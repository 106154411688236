import React, { useEffect, useState } from "react"
import { Popconfirm, Tooltip, message } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as Utils from "@/utils"
import AccountAvatar from "@/components/common/AccountAvatar"
import { Link } from "gatsby"

const AccountsList = () => {
  const dispatch = useAppDispatch()
  const accounts = useAppSelector(AccountSelectors.accountsExtended)
  const currentAccount = useAppSelector(AccountSelectors.currentAccount)
  const hideBalances = useAppSelector((state) => state.app.hideBalances)
  const pendingTxs = useAppSelector((state) => state.transaction.pendingTxs)

  return (
    <div>
      {accounts.length <= 0 && (
        <div className="xray-empty mt--1 mb--1 py-4">
          <div className="text-center">
            <strong>No accounts connected</strong>
            <br />
            <span className="text-muted font-size-14">Connect your account below to start using the app</span>
            <br />
            <span className="font-size-14 text-muted">
              Need help?{" "}
              <strong>
                <Link to="/wiki" onClick={() => dispatch(AppActions.MODAL_ACCOUNTS_SET(false))}>
                  Read our Wiki
                </Link>
              </strong>
            </span>
          </div>
        </div>
      )}
      {accounts.length > 0 &&
        accounts.map((account) => {
          const quantity = Utils.quantityFormat(account?.state?.balance.value || 0, 6, true)
          const isAccountPendingTx = pendingTxs?.[account.id] && !pendingTxs?.[account.id]?.seenOnBlockchain
          const isActive = currentAccount?.id === account.id
          return (
            <div className="xray-connect" key={account.id}>
              <div
                className={classNames("xray-connect-item", {
                  "xray-connect-item-active": isActive,
                })}
                onClick={() => {
                  if (isActive) {
                    dispatch(AccountActions.UPDATE_BALANCE_SAGA(account.id, false))
                  } else {
                    dispatch(AccountActions.CURRENT_ACCOUNT_ID_UPDATE_SAGA(account.id))
                  }
                }}
              >
                <span className="xray-connect-item-check">
                  <span />
                  <i className="xi xi-check" />
                </span>
                <span className="xray-connect-item-img">
                  <AccountAvatar
                    icon={account.web3export.paymentAddress || ""}
                    loading={!!account.loading}
                    sending={isAccountPendingTx}
                  />
                </span>
                <span className="xray-connect-item-title">
                  <div>
                    <strong>
                      {account.name.type === "address" && (
                        <div>{Utils.truncate(account.web3export.paymentAddress)}</div>
                      )}
                      {account.name.type === "custom" && (
                        <>
                          {account.name.custom && <div>{account.name.custom}</div>}
                          {!account.name.custom && <div>{Utils.truncate(account.web3export.paymentAddress)}</div>}
                        </>
                      )}
                      {account.name.type === "handle" && (
                        <>
                          {account.name.handle && <div>${account.name.handle}</div>}
                          {!account.name.handle && <div>{Utils.truncate(account.web3export.paymentAddress)}</div>}
                        </>
                      )}
                    </strong>
                  </div>
                  <div className="font-size-12 text-muted-mono">
                    {hideBalances && (
                      <span>
                        <strong>***</strong> <span className="font-weight-normal">ADA — </span>
                      </span>
                    )}
                    {!hideBalances && (
                      <span>
                        <strong>{quantity.a}</strong>
                        <span className="font-weight-normal">{quantity.b && `.${quantity.b}`} ADA — </span>
                      </span>
                    )}
                    <span>
                      {account.type === "connector" && <span>{account.connectorId}</span>}
                      {account.type === "xprv" && <span>private key</span>}
                      {account.type === "address" && <span>readonly</span>}
                      {account.canSign && <span className="xray-dot ms-1" />}
                    </span>
                  </div>
                </span>
                <span className="xray-connect-item-status">
                  {!isActive && <i className="xi xi-arrow_forward me-1" />}
                  {isActive && <i className="xi xi-check me-1" />}
                </span>
              </div>
              {/* <CopyToClipboard text={account.web3export.paymentAddress} onCopy={() => message.success("Copied")}>
                <Tooltip title="Copy address">
                  <div className="xray-connect-item xray-connect-item-control">
                    <i className="xi xi-copy" />
                  </div>
                </Tooltip>
              </CopyToClipboard> */}
              <Popconfirm
                placement="topRight"
                title="Delete account data?"
                onConfirm={() => {
                  dispatch(AccountActions.DELETE_ACCOUNT_SAGA(account.id))
                }}
                okText="Yes"
                cancelText="No"
              >
                <div className="xray-connect-item xray-connect-item-control">
                  <i className="xi xi-trash" />
                </div>
              </Popconfirm>
            </div>
          )
        })}
    </div>
  )
}

export default AccountsList
