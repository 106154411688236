import React, { useEffect, useState } from "react"
import { Modal, Button, Switch, Radio, Select, Input, Popconfirm, Statistic } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AccountActions, AccountSelectors, AccountTypes } from "@/redux/account"
import Informers from "@/components/informers"
import NetworkStats from "@/components/common/NetworkStats"
import * as Utils from "@/utils"

const AccountInfo = () => {
  const dispatch = useAppDispatch()
  const currentAccount = useAppSelector(AccountSelectors.currentAccountExtended)

  return (
    <>
      {currentAccount && (
        <div>
          <div className="overflow-hidden">
            <div className="d-flex align-items-center flex-wrap me--5 mb-2">
              <div className="me-5 mb-3 font-size-16">
                <Informers.Ada
                  quantity={currentAccount.state?.balance.value || "0"}
                  title="Balance"
                  help="Current address balance"
                  sameSize
                />
              </div>
              <div className="me-5 mb-3 font-size-16">
                <Informers.Ada
                  quantity={currentAccount.state?.rewards || "0"}
                  title="Rewards"
                  help="Rewards available for withdrawal"
                  sameSize
                />
              </div>
              <div className="me-5 mb-3 font-size-16">
                <Informers.Text
                  value={Utils.truncate(currentAccount.web3export.paymentAddress || "")}
                  title="Address"
                  help="Your account main payment address"
                  copy={currentAccount.web3export.paymentAddress}
                />
              </div>
              <div className="me-5 mb-3 font-size-16">
                <Informers.Text
                  value={
                    currentAccount.web3export.stakingAddress
                      ? Utils.truncate(currentAccount.web3export.stakingAddress)
                      : "—"
                  }
                  title="Staking Address"
                  help="Your account staking address"
                  copy={currentAccount.web3export.stakingAddress}
                />
              </div>
              <div className="me-5 mb-3 font-size-16">
                <Informers.Text
                  value={currentAccount.state?.delegation ? Utils.truncate(currentAccount.state.delegation) : "—"}
                  title="Delegation Pool"
                  help="Delegated staking pool"
                  copy={currentAccount.state?.delegation}
                />
              </div>
              {/* <div className="me-5 mb-4 font-size-21">
                <Informers.Text value="addr1...124125" title="Delegation DRep" />
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AccountInfo
