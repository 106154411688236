import { ConfigTypes } from "@/config"
import { FiatPriceResponse } from "@/services/fiatPrice"

/**
 * App Types
 */
export type Theme = "default" | "dark" | "system"
export type Scale = "small" | "default" | "large" | "xlarge"
export type Explorer = "cardanoscan" | "cexplorer" | "adastat"
export type ConnectorOnline = {
  id: string
  isEnabled: boolean
  address: string
  networkId: ConfigTypes.NetworkId
}

export interface ISettings {
  loaded: boolean
  cookiesViewed: boolean
  theme: Theme
  scale: Scale
  modalAccounts: boolean
  modalAddAccount: boolean
  modalSettings: boolean
  network: ConfigTypes.Network
  explorer: Explorer
  currency: ConfigTypes.Currencies
  hideBalances: boolean
  hideWelcome: boolean
  accountAddedSuccessfully: boolean
  tip: ConfigTypes.T.Tip | null
  connectorsOnline: ConnectorOnline[]
  fiatPrice: FiatPriceResponse | null
  web3: {
    instance: ConfigTypes.CardanoWeb3 | null
  }
}

/**
 * App Actions
 */

export enum Enum {
  CLEAR = "app/CLEAR",
  INIT_APP = "app/INIT_APP",

  LOADED_SET = "app/LOADED_SET",
  LOCK_SET = "app/LOCK_SET",
  COOKIES_VIEWED_SET = "app/COOKIES_VIEWED_SET",
  THEME_SET = "app/THEME_SET",
  SCALE_SET = "app/SCALE_SET",
  MODAL_ACCOUNTS_SET = "app/MODAL_ACCOUNTS_SET",
  MODAL_ADD_ACCOUNT_SET = "app/MODAL_ADD_ACCOUNT_SET",
  MODAL_SETTINGS_SET = "app/MODAL_SETTINGS_SET",
  MODAL_IN_PROGRESS_SET = "app/MODAL_IN_PROGRESS_SET",
  WEB3_SET = "web3/WEB3_SET",
  CONNECTORS_ONLINE_SET = "app/CONNECTORS_ONLINE_SET",
  NETWORK_SET = "app/NETWORK_SET",
  TIP_SET = "app/TIP_SET",
  EXPLORER_SET = "app/EXPLORER_SET",
  CURRENCY_SET = "app/CURRENCY_SET",
  HIDE_BALANCES_SET = "app/HIDE_BALANCES_SET",
  FIAT_PRICE_SET = "app/FIAT_PRICE_SET",
  HIDE_WELCOME_SET = "app/HIDE_WELCOME_SET",
  ACCOUNT_ADDED_SUCCESSFULLY_SET = "app/ACCOUNT_ADDED_SUCCESSFULLY_SET",

  WEB3_INIT_SAGA = "web3/WEB3_INIT_SAGA",
  CONNECTORS_ONLINE_UPDATE_SAGA = "app/CONNECTORS_ONLINE_UPDATE_SAGA",
  NETWORK_UPDATE_SAGA = "app/NETWORK_UPDATE_SAGA",
  TIP_UPDATE_SAGA = "app/TIP_UPDATE_SAGA",
  FIAT_PRICE_UPDATE_SAGA = "app/FIAT_PRICE_UPDATE_SAGA",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AInitApp = {
  type: typeof Enum.INIT_APP
}

export type ALoadedSet = {
  type: typeof Enum.LOADED_SET
  loaded: boolean
}

export type ALockSet = {
  type: typeof Enum.LOCK_SET
  locked: boolean
}

export type ACookiesViewedSet = {
  type: typeof Enum.COOKIES_VIEWED_SET
  cookiesViewed: boolean
}

export type AThemeSet = {
  type: typeof Enum.THEME_SET
  theme: Theme
}

export type AScaleSet = {
  type: typeof Enum.SCALE_SET
  scale: Scale
}

export type AModalAccountsSet = {
  type: typeof Enum.MODAL_ACCOUNTS_SET
  modalAccounts: boolean
}

export type AModalAddAccountSet = {
  type: typeof Enum.MODAL_ADD_ACCOUNT_SET
  modalAddAccount: boolean
}

export type AModalSettingsSet = {
  type: typeof Enum.MODAL_SETTINGS_SET
  modalSettings: boolean
}

export type ANetworkSet = {
  type: typeof Enum.NETWORK_SET
  network: ConfigTypes.Network
}

export type AWeb3Set = {
  type: typeof Enum.WEB3_SET
  web3: ConfigTypes.CardanoWeb3
}

export type AConnectorsOnlineSet = {
  type: typeof Enum.CONNECTORS_ONLINE_SET
  connectorsOnline: ConnectorOnline[]
}

export type ATipSet = {
  type: typeof Enum.TIP_SET
  tip: ConfigTypes.T.Tip
}

export type AExplorerSet = {
  type: typeof Enum.EXPLORER_SET
  explorer: Explorer
}

export type ACurrencySet = {
  type: typeof Enum.CURRENCY_SET
  currency: ConfigTypes.Currencies
}

export type AHideBalancesSet = {
  type: typeof Enum.HIDE_BALANCES_SET
  hideBalances: boolean
}

export type AHideWelcomeSet = {
  type: typeof Enum.HIDE_WELCOME_SET
  hideWelcome: boolean
}

export type AAccountAddedSuccessfully = {
  type: typeof Enum.ACCOUNT_ADDED_SUCCESSFULLY_SET
  accountAddedSuccessfully: boolean
}

export type AFiatPriceSet = {
  type: typeof Enum.FIAT_PRICE_SET
  fiatPrice: FiatPriceResponse
}

export type ANetworkUpdateSaga = {
  type: typeof Enum.NETWORK_UPDATE_SAGA
  networkName: ConfigTypes.NetworkName
}

export type AWeb3InitSaga = {
  type: typeof Enum.WEB3_INIT_SAGA
  networkName: ConfigTypes.NetworkName
}

export type AConnectorsOnlineUpdateSaga = {
  type: typeof Enum.CONNECTORS_ONLINE_UPDATE_SAGA
}

export type ATipUpdateSaga = {
  type: typeof Enum.TIP_UPDATE_SAGA
}

export type AFiatPriceUpdateSaga = {
  type: typeof Enum.FIAT_PRICE_UPDATE_SAGA
}

export type Actions =
  | AClear
  | AInitApp
  | ALoadedSet
  | ALockSet
  | ACookiesViewedSet
  | AThemeSet
  | AScaleSet
  | AModalAccountsSet
  | AModalAddAccountSet
  | AModalSettingsSet
  | ANetworkSet
  | AWeb3Set
  | ATipSet
  | AFiatPriceSet
  | AExplorerSet
  | ACurrencySet
  | AHideBalancesSet
  | AHideWelcomeSet
  | AAccountAddedSuccessfully
  | ANetworkUpdateSaga
  | AWeb3InitSaga
  | AConnectorsOnlineSet
  | AConnectorsOnlineUpdateSaga
  | ATipUpdateSaga
  | AFiatPriceUpdateSaga
