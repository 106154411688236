import React from "react"
import { Tooltip, message } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import classNames from "classnames"
import * as style from "./style.module.scss"

const InformerText = ({
  value,
  title,
  help,
  copy,
}: {
  value: string | number | React.ReactNode
  title?: string
  help?: string
  copy?: string | null | undefined
}) => {
  return (
    <div className={classNames(style.informer)}>
      <div className={style.body}>
        <strong>{value}</strong>
        {copy && (
          <span className={style.postfix}>
            <CopyToClipboard text={copy} onCopy={() => message.success("Copied")}>
              <Tooltip title="Copy">
                <i className="xi xi-copy ms-2 cursor-pointer" />
              </Tooltip>
            </CopyToClipboard>
          </span>
        )}
      </div>
      {title && (
        <div className={style.title}>
          {title}{" "}
          {help && (
            <Tooltip title={help}>
              <i className="xi xi-info" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default InformerText
