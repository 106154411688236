import { RootState, useAppSelector } from "../provider"
import { createSelector } from "@reduxjs/toolkit"
import { AccountTypes } from "@/redux/account"
import { AppTypes } from "@/redux/app"
import { ConfigTypes } from "@/config"
import { miniApps, widgets } from "@/config"

const miniAppsMain = miniApps.filter((app) => app.type === "inner")
const miniAppsDev = miniApps.filter((app) => app.type === "dev")

export const installed = createSelector(
  [(state: RootState) => state.account.miniApps, (state: RootState) => state.app.network],
  (miniAppsInstalled, network) => {
    return (
      miniAppsInstalled
        .map((id) => miniApps.find((app) => app.id === id))
        .filter((app) => app !== undefined)
        .filter((app) => app.network.includes(network.name)) || []
    )
  }
)

export const all = createSelector([(state: RootState) => state.app.network], (network) => {
  return miniApps.filter((app) => app.network.includes(network.name) && app.enabled) || []
})

export const inner = createSelector([(state: RootState) => state.app.network], (network) => {
  return miniAppsMain.filter((app) => app.network.includes(network.name) && app.enabled) || []
})

export const dev = createSelector([(state: RootState) => state.app.network], (network) => {
  return miniAppsDev.filter((app) => app.network.includes(network.name) && app.enabled) || []
})

export const inDevelopment = createSelector([(state: RootState) => state.app.network], (network) => {
  return miniApps.filter((app) => app.network.includes(network.name) && !app.enabled) || []
})
