import React, { useEffect, useState } from "react"
import { Button } from "antd"
import classNames from "classnames"
import Logo from "@/components/common/Logo"
import { AppActions } from "@/redux/app"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AccountSelectors } from "@/redux/account"
import { TransactionSelectors } from "@/redux/transaction"
import { MiniappsSelectors } from "@/redux/miniapps"
import AccountAvatar from "@/components/common/AccountAvatar"
import * as style from "./style.module.scss"
import { Link } from "gatsby"
import * as Utils from "@/utils"

const Header = () => {
  const dispatch = useAppDispatch()
  const installedMiniApps = useAppSelector((state) => state.account.miniApps)
  const currentAccount = useAppSelector(AccountSelectors.currentAccountExtended)
  const hideBalances = useAppSelector((state) => state.app.hideBalances)
  const network = useAppSelector((state) => state.app.network)
  const quantity = Utils.quantityFormat(currentAccount?.state?.balance.value || 0, 6, true)
  const currentAccountPendingTx = useAppSelector(TransactionSelectors.currentAccountPendingTx)
  const miniAppsInstalled = useAppSelector(MiniappsSelectors.installed)
  const miniApps = useAppSelector(MiniappsSelectors.all)

  const [menuOpen, setMenuOpen] = useState(false)

  const openMenu = () => {
    setMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuOpen(false)
    if (global?.window) {
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className={style.header}>
      <div className={classNames("xray-mobile-menu", { "xray-mobile-menu-opened": menuOpen })}>
        <div className="xray-mobile-menu-item-outer">
          <Link
            to="/"
            className="xray-mobile-menu-item"
            activeClassName="xray-mobile-menu-item-active"
            onClick={closeMenu}
          >
            Dashboard <sup>{installedMiniApps.length}</sup>
          </Link>
        </div>
        <div className="xray-mobile-menu-item-outer">
          <Link
            to="/mini-apps"
            className="xray-mobile-menu-item"
            activeClassName="xray-mobile-menu-item-active"
            onClick={closeMenu}
            partiallyActive
          >
            Mini Apps <sup>{miniApps.length}</sup>
          </Link>
        </div>
        <div className="xray-mobile-menu-item-outer">
          <Link
            to="/wiki"
            className="xray-mobile-menu-item"
            activeClassName="xray-mobile-menu-item-active"
            onClick={closeMenu}
            partiallyActive
          >
            Wiki
          </Link>
        </div>
        <div className="xray-mobile-menu-close" onClick={closeMenu}>
          <i className="xi xi-close" />
        </div>
      </div>
      <Link to="/">
        <Logo size={1} color="mono" name="App" />
      </Link>
      <div className={classNames(style.menu, "d-md-flex", "d-none")}>
        <Link to="/" className={style.item} activeClassName={style.itemActive}>
          Dashboard <sup>{miniAppsInstalled.length}</sup>
        </Link>
        <Link to="/mini-apps" className={style.item} activeClassName={style.itemActive} partiallyActive>
          Mini Apps <sup>{miniApps.length}</sup>
        </Link>
        <Link to="/wiki" className={style.item} activeClassName={style.itemActive} partiallyActive>
          Wiki
        </Link>
      </div>
      <div className="ms-auto w-min-200 d-flex justify-content-end">
        {!currentAccount && (
          <div className="d-flex">
            <Button
              name="account"
              size="large"
              shape="round"
              variant="filled"
              color="default"
              className={`${style.btnMono} rounded-top-right-none rounded-bottom-right-none`}
              onClick={() => dispatch(AppActions.MODAL_ADD_ACCOUNT_SET(true))}
            >
              <i className="xi xi-add" />
              <strong className="ms-1">Connect</strong>
            </Button>
            <div className="d-none d-md-flex">
              <Button
                name="settings"
                size="large"
                shape="round"
                variant="filled"
                color="default"
                className={`${style.btnMono} ${style.border} px-2 rounded-top-left-none rounded-bottom-left-none`}
                onClick={() => dispatch(AppActions.MODAL_SETTINGS_SET(true))}
              >
                <i className="xi xi-settings ms-1 me-2" />
              </Button>
            </div>
            <div className="d-flex d-md-none">
              <Button
                name="settings"
                size="large"
                shape="round"
                variant="filled"
                color="default"
                className={`${style.btnMono} ${style.border} px-2 rounded-none`}
                onClick={() => dispatch(AppActions.MODAL_SETTINGS_SET(true))}
              >
                <i className="xi xi-settings ms-1 me-1" />
              </Button>
              <Button
                name="settings"
                size="large"
                shape="round"
                variant="filled"
                color="default"
                className={`${style.btnMono} ${style.border} px-2 rounded-top-left-none rounded-bottom-left-none`}
                onClick={openMenu}
              >
                <i className="xi xi-menu ms-1 me-2" />
              </Button>
            </div>
          </div>
        )}
        {currentAccount && (
          <div className="d-flex">
            <Button
              name="account"
              size="large"
              shape="round"
              variant="filled"
              color="default"
              className="rounded-top-right-none rounded-bottom-right-none px-0"
              onClick={() => dispatch(AppActions.MODAL_ACCOUNTS_SET(true))}
            >
              <div className="d-flex align-items-center">
                <AccountAvatar
                  icon={currentAccount.web3export.paymentAddress || ""}
                  loading={!!currentAccount.loading}
                  sending={currentAccountPendingTx && !currentAccountPendingTx?.seenOnBlockchain}
                />
                <div className="d-flex align-items-start flex-nowrap flex-column ms-2 me-2 font-size-16 lh-11">
                  {currentAccount.name.type === "address" && (
                    <div>
                      <strong>{Utils.truncate(currentAccount.web3export.paymentAddress, 0)}</strong>
                    </div>
                  )}
                  {currentAccount.name.type === "custom" && (
                    <>
                      {currentAccount.name.custom && (
                        <div>
                          <strong>{currentAccount.name.custom}</strong>
                        </div>
                      )}
                      {!currentAccount.name.custom && (
                        <div>
                          <strong>{Utils.truncate(currentAccount.web3export.paymentAddress, 0)}</strong>
                        </div>
                      )}
                    </>
                  )}
                  {currentAccount.name.type === "handle" && (
                    <>
                      {currentAccount.name.handle && (
                        <div>
                          <strong>${currentAccount.name.handle}</strong>
                        </div>
                      )}
                      {!currentAccount.name.handle && (
                        <div>
                          <strong>{Utils.truncate(currentAccount.web3export.paymentAddress, 0)}</strong>
                        </div>
                      )}
                    </>
                  )}
                  <div className="font-size-10">
                    {hideBalances && (
                      <span>
                        <strong>***</strong> <span className="font-weight-normal">ADA</span>
                      </span>
                    )}
                    {!hideBalances && (
                      <span>
                        <strong>{quantity.a}</strong>
                        <span className="font-weight-normal">{quantity.b && `.${quantity.b}`} ADA</span>
                      </span>
                    )}
                    {currentAccount.canSign && <span className="xray-dot ms-1" />}
                  </div>
                </div>
              </div>
            </Button>
            <div className="d-none d-md-flex">
              <Button
                name="settings"
                size="large"
                shape="round"
                variant="filled"
                color="default"
                className={`${style.border} px-2 rounded-top-left-none rounded-bottom-left-none`}
                onClick={() => dispatch(AppActions.MODAL_SETTINGS_SET(true))}
              >
                <i className="xi xi-settings ms-1 me-2" />
              </Button>
            </div>
            <div className="d-flex d-md-none">
              <Button
                name="settings"
                size="large"
                shape="round"
                variant="filled"
                color="default"
                className={`${style.border} px-2 rounded-none`}
                onClick={() => dispatch(AppActions.MODAL_SETTINGS_SET(true))}
              >
                <i className="xi xi-settings ms-1 me-1" />
              </Button>
              <Button
                name="settings"
                size="large"
                shape="round"
                variant="filled"
                color="default"
                className={`${style.border} px-2 rounded-top-left-none rounded-bottom-left-none`}
                onClick={openMenu}
              >
                <i className="xi xi-menu ms-1 me-2" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
