import { MiniappsTypes } from "."
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"
import { LOCAL_STORAGE_PREFIX_GLOBAL } from "@/config"

const LOCAL_STORAGE_PREFIX = LOCAL_STORAGE_PREFIX_GLOBAL + "miniapps."
const setLocalstorage = (key: string, value: any) => {
  setPersistSetting(LOCAL_STORAGE_PREFIX, key, value)
}

const initialState: MiniappsTypes.ISettings = {
  stakeBookmarks: [],
  stakePoolsCurrency: "ada",
}

export default (
  state: MiniappsTypes.ISettings = { ...getPersistSettings(LOCAL_STORAGE_PREFIX, initialState) },
  action: MiniappsTypes.Actions
): MiniappsTypes.ISettings => {
  switch (action.type) {
    case MiniappsTypes.Enum.CLEAR:
      return initialState

    case MiniappsTypes.Enum.STAKE_BOOKMARKS_ADD_SAGA:
      console.log(123123)
      const stakeBookmarks = [...state.stakeBookmarks]
      stakeBookmarks.push(action.stakePool)
      setLocalstorage("stakeBookmarks", stakeBookmarks)
      return { ...state, stakeBookmarks }

    case MiniappsTypes.Enum.STAKE_BOOKMARKS_REMOVE_SAGA:
      const stakeBookmarksRemove = state.stakeBookmarks.filter((x) => x !== action.stakePool)
      setLocalstorage("stakeBookmarks", stakeBookmarksRemove)
      return { ...state, stakeBookmarks: stakeBookmarksRemove }

    case MiniappsTypes.Enum.STAKE_POOLS_CURRENCY_SET:
      setLocalstorage("stakePoolsCurrency", action.currency)
      return { ...state, stakePoolsCurrency: action.currency }

    default:
      return state
  }
}
