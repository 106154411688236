import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, Popconfirm } from "antd"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as utils from "@/utils"

const HardwareWallets = () => {
  const dispatch = useAppDispatch()

  return (
    <div>
      <div className="xray-connect">
        <div className="xray-connect-item xray-connect-item-inactive">
          <span className="xray-connect-item-img">
            <img src="/resources/wallets/ledger.svg" alt="Ledger" className="xray-inverse-on-dark" />
          </span>
          <span className="xray-connect-item-title">
            <strong className="d-flex align-items-center justify-items-center">Ledger</strong>
          </span>
          {/* <span className="xray-connect-item-status">
            <span>Soon</span>
          </span> */}
        </div>
      </div>
      <div className="xray-connect">
        <div className="xray-connect-item xray-connect-item-inactive">
          <span className="xray-connect-item-img">
            <img src="/resources/wallets/trezor.svg" alt="Trezor" className="xray-inverse-on-dark" />
          </span>
          <span className="xray-connect-item-title">
            <strong className="d-flex align-items-center justify-items-center">Trezor</strong>
          </span>
          {/* <span className="xray-connect-item-status">
            <span>Soon</span>
          </span> */}
        </div>
      </div>
    </div>
  )
}

export default HardwareWallets
