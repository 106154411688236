exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-index-tsx": () => import("./../../../src/pages/ai/index.tsx" /* webpackChunkName: "component---src-pages-ai-index-tsx" */),
  "component---src-pages-assets-index-tsx": () => import("./../../../src/pages/assets/index.tsx" /* webpackChunkName: "component---src-pages-assets-index-tsx" */),
  "component---src-pages-explorer-index-tsx": () => import("./../../../src/pages/explorer/index.tsx" /* webpackChunkName: "component---src-pages-explorer-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mini-apps-index-tsx": () => import("./../../../src/pages/mini-apps/index.tsx" /* webpackChunkName: "component---src-pages-mini-apps-index-tsx" */),
  "component---src-pages-stake-ada-bookmarks-tsx": () => import("./../../../src/pages/stake/ada/bookmarks.tsx" /* webpackChunkName: "component---src-pages-stake-ada-bookmarks-tsx" */),
  "component---src-pages-stake-ada-index-tsx": () => import("./../../../src/pages/stake/ada/index.tsx" /* webpackChunkName: "component---src-pages-stake-ada-index-tsx" */),
  "component---src-pages-stake-ada-pool-[id]-tsx": () => import("./../../../src/pages/stake/ada/pool/[id].tsx" /* webpackChunkName: "component---src-pages-stake-ada-pool-[id]-tsx" */),
  "component---src-pages-stake-ada-pools-tsx": () => import("./../../../src/pages/stake/ada/pools.tsx" /* webpackChunkName: "component---src-pages-stake-ada-pools-tsx" */),
  "component---src-pages-stake-ada-promoted-tsx": () => import("./../../../src/pages/stake/ada/promoted.tsx" /* webpackChunkName: "component---src-pages-stake-ada-promoted-tsx" */),
  "component---src-pages-stake-ada-xray-pools-tsx": () => import("./../../../src/pages/stake/ada/xray-pools.tsx" /* webpackChunkName: "component---src-pages-stake-ada-xray-pools-tsx" */),
  "component---src-pages-stake-index-tsx": () => import("./../../../src/pages/stake/index.tsx" /* webpackChunkName: "component---src-pages-stake-index-tsx" */),
  "component---src-pages-transactions-index-tsx": () => import("./../../../src/pages/transactions/index.tsx" /* webpackChunkName: "component---src-pages-transactions-index-tsx" */),
  "component---src-pages-wallet-index-tsx": () => import("./../../../src/pages/wallet/index.tsx" /* webpackChunkName: "component---src-pages-wallet-index-tsx" */),
  "component---src-pages-wiki-cardano-ada-staking-mdx": () => import("./../../../src/pages/wiki/cardano/ada-staking.mdx" /* webpackChunkName: "component---src-pages-wiki-cardano-ada-staking-mdx" */),
  "component---src-pages-wiki-cardano-drep-mdx": () => import("./../../../src/pages/wiki/cardano/drep.mdx" /* webpackChunkName: "component---src-pages-wiki-cardano-drep-mdx" */),
  "component---src-pages-wiki-cardano-smart-contracts-mdx": () => import("./../../../src/pages/wiki/cardano/smart-contracts.mdx" /* webpackChunkName: "component---src-pages-wiki-cardano-smart-contracts-mdx" */),
  "component---src-pages-wiki-cardano-wallets-and-accounts-mdx": () => import("./../../../src/pages/wiki/cardano/wallets-and-accounts.mdx" /* webpackChunkName: "component---src-pages-wiki-cardano-wallets-and-accounts-mdx" */),
  "component---src-pages-wiki-changelog-mdx": () => import("./../../../src/pages/wiki/changelog.mdx" /* webpackChunkName: "component---src-pages-wiki-changelog-mdx" */),
  "component---src-pages-wiki-dev-cardano-web-3-js-mdx": () => import("./../../../src/pages/wiki/dev/cardano-web3-js.mdx" /* webpackChunkName: "component---src-pages-wiki-dev-cardano-web-3-js-mdx" */),
  "component---src-pages-wiki-dev-mini-apps-mdx": () => import("./../../../src/pages/wiki/dev/mini-apps.mdx" /* webpackChunkName: "component---src-pages-wiki-dev-mini-apps-mdx" */),
  "component---src-pages-wiki-dev-xray-graph-mdx": () => import("./../../../src/pages/wiki/dev/xray-graph.mdx" /* webpackChunkName: "component---src-pages-wiki-dev-xray-graph-mdx" */),
  "component---src-pages-wiki-dev-xray-vault-mdx": () => import("./../../../src/pages/wiki/dev/xray-vault.mdx" /* webpackChunkName: "component---src-pages-wiki-dev-xray-vault-mdx" */),
  "component---src-pages-wiki-github-updates-mdx": () => import("./../../../src/pages/wiki/github-updates.mdx" /* webpackChunkName: "component---src-pages-wiki-github-updates-mdx" */),
  "component---src-pages-wiki-index-mdx": () => import("./../../../src/pages/wiki/index.mdx" /* webpackChunkName: "component---src-pages-wiki-index-mdx" */),
  "component---src-pages-wiki-install-app-pwa-mdx": () => import("./../../../src/pages/wiki/install-app-pwa.mdx" /* webpackChunkName: "component---src-pages-wiki-install-app-pwa-mdx" */),
  "component---src-pages-wiki-mini-apps-mdx": () => import("./../../../src/pages/wiki/mini-apps.mdx" /* webpackChunkName: "component---src-pages-wiki-mini-apps-mdx" */),
  "component---src-pages-wiki-privacy-policy-mdx": () => import("./../../../src/pages/wiki/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-wiki-privacy-policy-mdx" */),
  "component---src-pages-wiki-roadmap-mdx": () => import("./../../../src/pages/wiki/roadmap.mdx" /* webpackChunkName: "component---src-pages-wiki-roadmap-mdx" */),
  "component---src-pages-wiki-social-media-mdx": () => import("./../../../src/pages/wiki/social-media.mdx" /* webpackChunkName: "component---src-pages-wiki-social-media-mdx" */),
  "component---src-pages-wiki-terms-of-service-mdx": () => import("./../../../src/pages/wiki/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-wiki-terms-of-service-mdx" */),
  "component---src-pages-wiki-xray-app-mdx": () => import("./../../../src/pages/wiki/xray-app.mdx" /* webpackChunkName: "component---src-pages-wiki-xray-app-mdx" */),
  "component---src-pages-wiki-xray-foundation-mdx": () => import("./../../../src/pages/wiki/xray-foundation.mdx" /* webpackChunkName: "component---src-pages-wiki-xray-foundation-mdx" */),
  "component---src-pages-wiki-xray-graph-mdx": () => import("./../../../src/pages/wiki/xray-graph.mdx" /* webpackChunkName: "component---src-pages-wiki-xray-graph-mdx" */),
  "component---src-pages-wiki-xray-network-mdx": () => import("./../../../src/pages/wiki/xray-network.mdx" /* webpackChunkName: "component---src-pages-wiki-xray-network-mdx" */),
  "component---src-pages-wiki-xray-tokenomics-mdx": () => import("./../../../src/pages/wiki/xray-tokenomics.mdx" /* webpackChunkName: "component---src-pages-wiki-xray-tokenomics-mdx" */),
  "component---src-pages-wiki-xray-vault-mdx": () => import("./../../../src/pages/wiki/xray-vault.mdx" /* webpackChunkName: "component---src-pages-wiki-xray-vault-mdx" */)
}

