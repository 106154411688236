import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import { message, notification } from "antd"
import { navigate } from "gatsby"
import { AccountActions, AccountSelectors, AccountTypes } from "./"
import { typedSelect, typedCall } from "../provider"
import * as utils from "@/utils"
import { ADA_HANDLE_POLICY } from "@/config"
import { AppActions } from "@/redux/app"

/*
 * ACCOUNT HANDLERS
 */

export function* CURRENT_ACCOUNT_ID_UPDATE_SAGA({ id }: AccountTypes.ACurrentAccountIdUpdateSaga) {
  const network = yield* typedSelect((state) => state.app.network)
  const currentAccountId = yield* typedSelect((state) => state.account.currentAccountId)
  yield put(
    AccountActions.CURRENT_ACCOUNT_ID_SET({
      ...currentAccountId,
      [network.name]: id,
    })
  )
  // navigate("/" as any)
}

export function* ADD_ACCOUNT_SAGA({ account }: AccountTypes.AAddAccountSaga) {
  const accounts = yield* typedSelect((state) => state.account.accounts)
  const sameAccount = accounts.find((a) => {
    if (account.type === "connector" && a.type === "connector") {
      return (
        a.connectorId === account.connectorId &&
        a.type === account.type &&
        a.web3export?.paymentAddress === account.web3export?.paymentAddress
      )
    }
    return a.type === account.type && a.web3export?.paymentAddress === account.web3export?.paymentAddress
  })
  if (sameAccount) {
    message.error("Account already exist")
    // notification.info({
    //   message: "Account already exist",
    //   description: "Account already exist in your list",
    // })
    yield put(AccountActions.CURRENT_ACCOUNT_ID_UPDATE_SAGA(sameAccount.id))
  } else {
    message.success("Account added")
    // notification.success({
    //   message: "Account added",
    //   description: "Account added to your list",
    // })
    yield put(AccountActions.ACCOUNTS_SET([...accounts, account]))
    yield put(AccountActions.CURRENT_ACCOUNT_ID_UPDATE_SAGA(account.id))
    yield put(AppActions.ACCOUNT_ADDED_SUCCESSFULLY_SET(true))
  }
}

export function* DELETE_ACCOUNT_SAGA({ id }: AccountTypes.ADeleteAccountSaga) {
  const accounts = yield* typedSelect((state) => state.account.accounts)
  const instances = yield* typedSelect((state) => state.account.instances)
  const newAccounts = accounts.filter((account) => account.id !== id)
  const newInstances = instances.filter((instance) => instance.id !== id)
  yield put(AccountActions.ACCOUNTS_SET(newAccounts))
  yield put(AccountActions.INSTANCES_SET(newInstances))
  if (newAccounts.length === 0) {
    yield put(AccountActions.CURRENT_ACCOUNT_ID_UPDATE_SAGA(null))
  } else {
    const network = yield* typedSelect((state) => state.app.network)
    const currentAccountId = (yield* typedSelect((state) => state.account.currentAccountId))[network.name]
    if (currentAccountId === id) {
      yield put(AccountActions.CURRENT_ACCOUNT_ID_UPDATE_SAGA(newAccounts[0].id))
    }
  }
  message.error("Account deleted")
  // notification.info({
  //   message: "Account deleted",
  //   description: "Account deleted from your list",
  // })
}

export function* UPDATE_INSTANCE_SAGA({ instance }: AccountTypes.AUpdateInstanceSaga) {
  const instances = yield* typedSelect((state) => state.account.instances)
  const isExist = instances.find((a) => a.id === instance.id)
  if (!isExist) {
    yield put(AccountActions.INSTANCES_SET([...instances, instance]))
  } else {
    const newInstances = instances.map((a) => (a.id === instance.id ? instance : a))
    yield put(AccountActions.INSTANCES_SET(newInstances))
  }
}

export function* UPDATE_BALANCE_SAGA({ id, silent }: AccountTypes.AUpdateBalanceSaga) {
  try {
    const instance = yield* typedSelect((state) => state.account.instances.find((a) => a.id === id))
    if (instance) {
      if (!silent) {
        yield put(AccountActions.BALANCE_LOADING_SET(instance!.id, true)) // set loading true
      }
      const state = yield* typedCall(instance.web3account!.getState) || null
      const balance = {
        id: instance!.id,
        loading: false, // set loading false
        updatedAt: Date.now(),
        state,
      }

      // Set handles
      const handles: string[] = []
      balance.state?.balance.assets.forEach((asset) => {
        if (asset.policyId === ADA_HANDLE_POLICY) {
          handles.push(asset.assetNameAscii)
        }
      })
      yield put(AccountActions.HANDLES_UPDATE_SAGA({ id, handles }))
      const name = yield* typedSelect((state) => state.account.names.find((a) => a.id === id))
      if (name?.handle && !handles.includes(name.handle)) {
        yield put(AccountActions.NAME_UPDATE_SAGA({ ...name, handle: "" }))
      } else {
        if (handles.length > 0 && !name?.handle) {
          // set address type by default, but handle also set
          yield put(AccountActions.NAME_UPDATE_SAGA({ id, type: "address", handle: handles[0], custom: "" }))
        }
      }

      // Set balances
      const balances = yield* typedSelect((state) => state.account.balances)
      const isExist = balances.find((a) => a.id === instance.id)
      if (!isExist) {
        yield put(AccountActions.BALANCES_SET([...balances, balance]))
      } else {
        const newBalances = balances.map((a) => (a.id === instance.id ? balance : a))
        yield put(AccountActions.BALANCES_SET(newBalances))
      }
    }
  } catch {
    yield put(AccountActions.BALANCE_LOADING_SET(id, false)) // set loading false
  }
}

export function* NAME_UPDATE_SAGA({ name }: AccountTypes.ANameUpdateSaga) {
  const names = yield* typedSelect((state) => state.account.names)
  const isExist = names.find((a) => a.id === name.id)
  if (!isExist) {
    yield put(AccountActions.NAMES_SET([...names, name]))
  } else {
    const newNames = names.map((a) => (a.id === name.id ? name : a))
    yield put(AccountActions.NAMES_SET(newNames))
  }
}

export function* HANDLES_UPDATE_SAGA({ handle }: AccountTypes.AHandlesUpdateSaga) {
  const handles = yield* typedSelect((state) => state.account.handles)
  const isExist = handles.find((a) => a.id === handle.id)
  if (!isExist) {
    yield put(AccountActions.HANDLES_SET([...handles, handle]))
  } else {
    const newHandles = handles.map((a) => (a.id === handle.id ? handle : a))
    yield put(AccountActions.HANDLES_SET(newHandles))
  }
}

/*
 * CONNECTORS
 */

export function* ADD_BROWSER_CONNECTOR_SAGA({ wallet }: AccountTypes.AAddBrowserConnectorSaga) {
  const web3 = yield* typedSelect((state) => state.app.web3.instance)
  const network = yield* typedSelect((state) => state.app.network)
  const id = utils.randomString()
  try {
    const connector = yield* typedCall(web3!.connector.init, wallet)
    const account = yield* typedCall(web3!.account.fromConnector, connector)
    const init = {
      revision: 1,
      id,
      type: "connector",
      connectorId: wallet,
      network,
      web3export: account.exportAccount(),
    } as AccountTypes.IAccount
    yield put(AppActions.CONNECTORS_ONLINE_UPDATE_SAGA())
    yield put(AccountActions.ADD_ACCOUNT_SAGA(init))
    yield put(AccountActions.INIT_BROWSER_CONNECTOR_SAGA(init))
  } catch (e) {
    const isNetworkMismatch = (e as any)?.message === "Connector network mismatch"
    if (isNetworkMismatch) {
      // message.error("Wrong network. Check your wallet account")
      notification.error({
        message: "Wrong network",
        description: "Check your wallet account",
      })
    } else {
      // message.info("Action cancelled")
      notification.info({
        message: "Action cancelled",
        description: "Action cancelled due to some reason",
      })
    }
  }
}

export function* INIT_BROWSER_CONNECTOR_SAGA({ account }: AccountTypes.AInitBrowserConnectorSaga) {
  const web3 = yield* typedSelect((state) => state.app.web3.instance)
  const network = yield* typedSelect((state) => state.app.network)
  const currentAccountId = (yield* typedSelect((state) => state.account.currentAccountId))[network.name]
  const connectorsOnline = yield* typedSelect((state) => state.app.connectorsOnline)

  const instances = yield* typedSelect((state) => state.account.instances)
  const currentInstance = instances.find((a) => a.id === currentAccountId)?.web3account
  const currentIterateInstance = instances.find((a) => a.id === account.id)?.web3account

  const isIterateAccountCurrentAccount = account.id === currentAccountId
  const isCurrentInstanceConnector = currentInstance?.__config.type === "connector"
  const isIterateAccountReadonly = currentIterateInstance?.__config.type === "address"
  const isAccountInstanceOnline = !!connectorsOnline.find(
    (c) => c.id === account?.connectorId && c.address === account?.web3export?.paymentAddress
  )

  if (isIterateAccountCurrentAccount && isAccountInstanceOnline) {
    if (isCurrentInstanceConnector) return
    const connector = yield* typedCall(web3!.connector.init, account.connectorId!)
    const web3account = yield* typedCall(web3!.account.fromConnector, connector)
    yield put(
      AccountActions.UPDATE_INSTANCE_SAGA({
        id: account.id,
        web3account,
      })
    )
  } else {
    if (isIterateAccountReadonly) return
    const web3account = yield* typedCall(web3!.account.fromAddress, account?.web3export?.paymentAddress!)
    yield put(
      AccountActions.UPDATE_INSTANCE_SAGA({
        id: account.id,
        web3account,
      })
    )
  }
}

export function* ADD_XPRV_KEY_SAGA({ xprvKey, password }: AccountTypes.AAddXprvKeySaga) {
  const web3 = yield* typedSelect((state) => state.app.web3.instance)
  const network = yield* typedSelect((state) => state.app.network)
  const id = utils.randomString()
  const account = yield* typedCall(web3!.account.fromXprvKey, xprvKey)
  account.encodeAndUpdateXprvKey(password)
  yield put(
    AccountActions.ADD_ACCOUNT_SAGA({
      revision: 1,
      id,
      type: "xprv",
      connectorId: null,
      network,
      web3export: account.exportAccount(),
    })
  )
}

export function* INIT_XPRV_KEY_SAGA({ account }: AccountTypes.AInitXprvKeySaga) {
  const web3 = yield* typedSelect((state) => state.app.web3.instance)
  const isInstanceExist = yield* typedSelect((state) => state.account.instances.find((a) => a.id === account.id))
  if (!isInstanceExist) {
    const web3account = yield* typedCall(web3!.account.importAccount, account.web3export)
    yield put(
      AccountActions.UPDATE_INSTANCE_SAGA({
        id: account.id,
        web3account,
      })
    )
  }
}

export function* ADD_READ_ONLY_SAGA({ address }: AccountTypes.AAddReadOnlySaga) {
  const web3 = yield* typedSelect((state) => state.app.web3.instance)
  const network = yield* typedSelect((state) => state.app.network)
  const id = utils.randomString()
  const account = yield* typedCall(web3!.account.fromAddress, address)
  yield put(
    AccountActions.ADD_ACCOUNT_SAGA({
      revision: 1,
      id,
      type: "address",
      connectorId: null,
      network,
      web3export: account.exportAccount(),
    })
  )
}

export function* INIT_READ_ONLY_SAGA({ account }: AccountTypes.AInitReadOnlySaga) {
  const web3 = yield* typedSelect((state) => state.app.web3.instance)
  const isInstanceExist = yield* typedSelect((state) => state.account.instances.find((a) => a.id === account.id))
  if (!isInstanceExist) {
    const web3account = yield* typedCall(web3!.account.importAccount, account.web3export)
    yield put(
      AccountActions.UPDATE_INSTANCE_SAGA({
        id: account.id,
        web3account,
      })
    )
  }
}

/*
 * ACCOUNTS INITIALIZATION & UPDATES
 */

export function* INIT_INSTANCES_SAGA() {
  const web3 = yield* typedSelect((state) => state.app.web3.instance)
  const network = yield* typedSelect((state) => state.app.network)
  const accounts = (yield* typedSelect((state) => state.account.accounts)).filter(
    (a) => a.network.name === network.name
  )
  for (const account of accounts) {
    try {
      if (web3?.__config.network.name !== account.network.name) return
      if (account.type === "connector") {
        yield put(AccountActions.INIT_BROWSER_CONNECTOR_SAGA(account))
      }
      if (account.type === "xprv") {
        yield put(AccountActions.INIT_XPRV_KEY_SAGA(account))
      }
      if (account.type === "address") {
        yield put(AccountActions.INIT_READ_ONLY_SAGA(account))
      }
    } catch {}
  }
}

/*
 * MINI APPS
 */

export function* MINI_APPS_INSTALL_SAGA({ id }: AccountTypes.AMiniAppsInstallSaga) {
  const miniApps = yield* typedSelect((state) => state.account.miniApps)
  const isExist = miniApps.find((a) => a === id)
  if (!isExist) {
    yield put(AccountActions.MINI_APPS_SET([...miniApps, id]))
    // message.success("Mini App installed to Dashboard")
    notification.success({
      message: "Mini App installed",
      description: "Mini App installed to Dashboard",
    })
  }
}

export function* MINI_APPS_UNINSTALL_SAGA({ id }: AccountTypes.AMiniAppsUninstallSaga) {
  const miniApps = yield* typedSelect((state) => state.account.miniApps)
  const newMiniApps = miniApps.filter((a) => a !== id)
  yield put(AccountActions.MINI_APPS_SET(newMiniApps))
  // message.success("Mini App uninstalled")
  notification.info({
    message: "Mini App uninstalled",
    description: "Mini App uninstalled from Dashboard",
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(AccountTypes.Enum.CURRENT_ACCOUNT_ID_UPDATE_SAGA, CURRENT_ACCOUNT_ID_UPDATE_SAGA),
    takeEvery(AccountTypes.Enum.ADD_ACCOUNT_SAGA, ADD_ACCOUNT_SAGA),
    takeEvery(AccountTypes.Enum.DELETE_ACCOUNT_SAGA, DELETE_ACCOUNT_SAGA),
    takeEvery(AccountTypes.Enum.UPDATE_INSTANCE_SAGA, UPDATE_INSTANCE_SAGA),

    takeEvery(AccountTypes.Enum.ADD_BROWSER_CONNECTOR_SAGA, ADD_BROWSER_CONNECTOR_SAGA),
    takeEvery(AccountTypes.Enum.INIT_BROWSER_CONNECTOR_SAGA, INIT_BROWSER_CONNECTOR_SAGA),
    takeEvery(AccountTypes.Enum.ADD_XPRV_KEY_SAGA, ADD_XPRV_KEY_SAGA),
    takeEvery(AccountTypes.Enum.INIT_XPRV_KEY_SAGA, INIT_XPRV_KEY_SAGA),
    takeEvery(AccountTypes.Enum.ADD_READ_ONLY_SAGA, ADD_READ_ONLY_SAGA),
    takeEvery(AccountTypes.Enum.INIT_READ_ONLY_SAGA, INIT_READ_ONLY_SAGA),

    takeEvery(AccountTypes.Enum.INIT_INSTANCES_SAGA, INIT_INSTANCES_SAGA),
    takeEvery(AccountTypes.Enum.UPDATE_BALANCE_SAGA, UPDATE_BALANCE_SAGA),
    takeEvery(AccountTypes.Enum.NAME_UPDATE_SAGA, NAME_UPDATE_SAGA),
    takeEvery(AccountTypes.Enum.HANDLES_UPDATE_SAGA, HANDLES_UPDATE_SAGA),
    takeEvery(AccountTypes.Enum.MINI_APPS_INSTALL_SAGA, MINI_APPS_INSTALL_SAGA),
    takeEvery(AccountTypes.Enum.MINI_APPS_UNINSTALL_SAGA, MINI_APPS_UNINSTALL_SAGA),
  ])
}
