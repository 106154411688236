/**
 * Transaction Types
 */

export interface ISettings {
  stakeBookmarks: string[]
  stakePoolsCurrency: "ada" | "currency"
}

/**
 * Transaction Actions
 */

export enum Enum {
  CLEAR = "miniapps/CLEAR",

  STAKE_BOOKMARKS_ADD_SAGA = "miniapps/STAKE_BOOKMARKS_ADD",
  STAKE_BOOKMARKS_REMOVE_SAGA = "miniapps/STAKE_BOOKMARKS_REMOVE",
  STAKE_POOLS_CURRENCY_SET = "miniapps/STAKE_POOLS_CURRENCY_SET",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AStakeBookmarksAddSaga = {
  type: typeof Enum.STAKE_BOOKMARKS_ADD_SAGA
  stakePool: string
}

export type AStakeBookmarksRemoveSaga = {
  type: typeof Enum.STAKE_BOOKMARKS_REMOVE_SAGA
  stakePool: string
}

export type AStakePoolsCurrencySet = {
  type: typeof Enum.STAKE_POOLS_CURRENCY_SET
  currency: "ada" | "currency"
}

export type Actions = AClear | AStakeBookmarksAddSaga | AStakeBookmarksRemoveSaga | AStakePoolsCurrencySet
