import { AccountTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"
import { LOCAL_STORAGE_PREFIX_GLOBAL } from "@/config"
import { produce } from "immer"

const LOCAL_STORAGE_PREFIX = LOCAL_STORAGE_PREFIX_GLOBAL + "account."
const setLocalstorage = (key: string, value: any) => {
  setPersistSetting(LOCAL_STORAGE_PREFIX, key, value)
}

const initialState: AccountTypes.ISettings = {
  currentAccountId: {
    mainnet: null,
    preprod: null,
    preview: null,
  },
  accounts: [],
  instances: [],
  balances: [],
  names: [],
  handles: [],
  miniApps: ["xray-wallet", "xray-assets", "xray-transactions", "xray-stake", "xray-wiki"],
}

export default (
  state: AccountTypes.ISettings = { ...getPersistSettings(LOCAL_STORAGE_PREFIX, initialState) },
  action: AccountTypes.Actions
): AccountTypes.ISettings => {
  switch (action.type) {
    case AccountTypes.Enum.CLEAR:
      return initialState

    case AccountTypes.Enum.ACCOUNTS_SET:
      setLocalstorage("accounts", action.accounts)
      return { ...state, accounts: action.accounts }

    case AccountTypes.Enum.INSTANCES_SET:
      return produce(state, (draft) => {
        draft.instances = action.instances
      })

    case AccountTypes.Enum.BALANCES_SET:
      return { ...state, balances: action.balances }

    case AccountTypes.Enum.BALANCE_LOADING_SET:
      const balances = state.balances.map((b) => (b.id === action.id ? { ...b, loading: action.loading } : b))
      return { ...state, balances }

    case AccountTypes.Enum.NAMES_SET:
      setLocalstorage("names", action.names)
      return { ...state, names: action.names }

    case AccountTypes.Enum.HANDLES_SET:
      return { ...state, handles: action.handles }

    case AccountTypes.Enum.MINI_APPS_SET:
      setLocalstorage("miniApps", action.miniApps)
      return { ...state, miniApps: action.miniApps }

    case AccountTypes.Enum.CURRENT_ACCOUNT_ID_SET:
      setLocalstorage("currentAccountId", action.currentAccountId)
      return { ...state, currentAccountId: action.currentAccountId }

    default:
      return state
  }
}
