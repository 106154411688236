import type { MiniApp } from "./types"

const miniApps: MiniApp[] = [
  /*
   * MAIN APPS
   */

  {
    id: "xray-wallet",
    name: "Wallet",
    network: ["mainnet", "preprod", "preview"],
    icon: "xray-wallet.png",
    description: "Secure and user-friendly Cardano wallet",
    link: "/wallet",
    enabled: true,
    type: "inner",
  },
  {
    id: "xray-assets",
    name: "Assets",
    network: ["mainnet", "preprod", "preview"],
    icon: "xray-assets.png",
    description: "Explore your account's assets & NFTs",
    link: "/assets",
    enabled: true,
    type: "inner",
  },
  {
    id: "xray-transactions",
    name: "Transactions",
    network: ["mainnet", "preprod", "preview"],
    icon: "xray-transactions.png",
    description: "Your account's transaction history",
    link: "/transactions",
    enabled: true,
    type: "inner",
  },
  {
    id: "xray-stake",
    name: "Stake",
    network: ["mainnet", "preprod", "preview"],
    icon: "xray-stake.png",
    description: "Stake Cardano assets for passive rewards",
    link: "/stake",
    enabled: true,
    type: "inner",
  },
  {
    id: "xray-ai",
    name: "AI",
    network: ["mainnet", "preprod", "preview"],
    icon: "xray-ai.png",
    description: "Advanced blockchain-driven AI tools and insights",
    link: "/ai",
    enabled: false,
    type: "inner",
  },
  {
    id: "xray-premium",
    name: "Premium",
    network: ["mainnet"],
    icon: "xray-premium.png",
    description: "Exclusive features and benefits for XRAY Premium users",
    link: "/premium",
    enabled: false,
    type: "inner",
  },
  {
    id: "xray-explorer",
    name: "Explorer",
    network: ["mainnet", "preprod", "preview"],
    icon: "xray-explorer.png",
    description: "Track Cardano transactions and blockchain stats",
    link: "/explorer",
    enabled: false,
    type: "inner",
  },
  {
    id: "xray-governance",
    name: "Governance",
    network: ["mainnet"],
    icon: "xray-governance.png",
    description: "Participate in XRAY/Network governance",
    link: "/governance",
    enabled: false,
    type: "inner",
  },
  {
    id: "xray-wiki",
    name: "Wiki",
    network: ["mainnet", "preprod", "preview"],
    icon: "xray-wiki.png",
    description: "Comprehensive knowledge base for Cardano",
    link: "/wiki",
    enabled: true,
    type: "inner",
  },
  {
    id: "xray-swap",
    name: "Swap",
    network: ["mainnet"],
    icon: "xray-swap.png",
    description: "DEX aggregator & token trading platform",
    link: "/swap",
    enabled: false,
    type: "inner",
  },
  {
    id: "xray-pad",
    name: "Pad",
    network: ["mainnet"],
    icon: "xray-pad.png",
    description: "Launchpad for Cardano-based blockchain projects",
    link: "/pad",
    enabled: false,
    type: "inner",
  },
  {
    id: "xray-list",
    name: "List",
    network: ["mainnet"],
    icon: "xray-list.png",
    description: "Token data and real-time pricing platform",
    link: "/list",
    enabled: false,
    type: "inner",
  },
  {
    id: "minterr-io",
    name: "Minterr",
    network: ["mainnet", "preprod", "preview"],
    icon: "minterr.png",
    description: "Effortless NFT creation on Cardano",
    link: "/minterr",
    enabled: false,
    type: "inner",
  },
  {
    id: "pigz-io",
    name: "Pigz.io",
    network: ["mainnet"],
    icon: "pigz-io.png",
    description: "Fun Telegram-based Cardano gaming app",
    link: "/pigz-io",
    enabled: false,
    type: "inner",
  },

  /*
   * DEV APPS
   */

  {
    id: "xray-graph",
    name: "Graph",
    network: ["mainnet", "preprod", "preview"],
    icon: "xray-graph.png",
    description: "Robust APIs for Cardano blockchain developers",
    link: "/graph",
    enabled: false,
    type: "dev",
  },
  {
    id: "xray-cardano-web3-js",
    name: "CardanoWeb3Js",
    network: ["mainnet", "preprod", "preview"],
    icon: "cardano-web3-js.png",
    description: "TypeScript library for seamless Cardano blockchain integration",
    link: "/cardano-web3-js",
    enabled: false,
    type: "dev",
  },
  {
    id: "xray-ma-devtools",
    name: "Mini App DevTools",
    network: ["mainnet", "preprod", "preview"],
    icon: "mini-app-devtools.png",
    description: "Mini App preview tool with advanced features",
    link: "/mini-app-loader",
    enabled: false,
    type: "dev",
  },
  {
    id: "xray-ma-registrar",
    name: "Mini App Registrar",
    network: ["mainnet", "preprod", "preview"],
    icon: "mini-app-registrar.png",
    description: "Mini App registration tool",
    link: "/mini-app-registrar",
    enabled: false,
    type: "dev",
  },
  {
    id: "xray-faucet",
    name: "Faucet",
    network: ["preprod"],
    icon: "xray-faucet.png",
    description: "Testnet tokens faucet for Cardano developers",
    link: "/faucet",
    enabled: false,
    type: "dev",
  },
]

export default miniApps
