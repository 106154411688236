import { ConfigTypes } from "@/config"
import { MiniappsTypes } from "."

export function CLEAR(): MiniappsTypes.AClear {
  return { type: MiniappsTypes.Enum.CLEAR }
}

export function STAKE_BOOKMARKS_ADD_SAGA(stakePool: string): MiniappsTypes.AStakeBookmarksAddSaga {
  return { type: MiniappsTypes.Enum.STAKE_BOOKMARKS_ADD_SAGA, stakePool }
}

export function STAKE_BOOKMARKS_REMOVE_SAGA(stakePool: string): MiniappsTypes.AStakeBookmarksRemoveSaga {
  return { type: MiniappsTypes.Enum.STAKE_BOOKMARKS_REMOVE_SAGA, stakePool }
}

export function STAKE_POOLS_CURRENCY_SET(currency: "ada" | "currency"): MiniappsTypes.AStakePoolsCurrencySet {
  return { type: MiniappsTypes.Enum.STAKE_POOLS_CURRENCY_SET, currency }
}
