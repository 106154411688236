import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Modal, Button, Collapse, Tag, Alert } from "antd"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as Utils from "@/utils"
import Vault from "./Vault"
import BrowserConnectors from "./BrowserConnectors"
import PrivateKey from "./PrivateKey"
import HardwareWallets from "./HardwareWallets"
import Readonly from "./Readonly"
import ImportAccount from "./ImportAccount"
import Informers from "@/components/informers"

const ModalAddAccount = () => {
  const dispatch = useAppDispatch()
  const web3 = useAppSelector((state) => state.app.web3.instance)
  const modalAddAccount = useAppSelector((state) => state.app.modalAddAccount)
  const accountAddedSuccessfully = useAppSelector((state) => state.app.accountAddedSuccessfully)
  const [isActive, setIsActive] = useState(false)

  return (
    <Modal
      title={null}
      open={modalAddAccount}
      onCancel={() => {
        setIsActive(false)
        dispatch(AppActions.ACCOUNT_ADDED_SUCCESSFULLY_SET(false))
        dispatch(AppActions.MODAL_ADD_ACCOUNT_SET(false))
      }}
      footer={null}
      width={650}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose={true}
    >
      <div className="mb-3">
        <strong>Connect Account</strong>
      </div>
      {accountAddedSuccessfully && (
        <div>
          <Alert
            description="Now you can use your account to interact with the Cardano ecosystem"
            message="Account added successfully"
            type="success"
            showIcon
            className="mb-4"
          />
          <div className="mb-4">
            <Button
              shape="round"
              className="w-100p"
              size="large"
              onClick={() => {
                setIsActive(false)
                dispatch(AppActions.ACCOUNT_ADDED_SUCCESSFULLY_SET(false))
                dispatch(AppActions.MODAL_ADD_ACCOUNT_SET(false))
              }}
            >
              <i className="xi xi-close font-size-12" />
              <strong>Close</strong>
            </Button>
          </div>
        </div>
      )}
      {!accountAddedSuccessfully && (
        <div>
          <Collapse
            destroyInactivePanel
            // defaultActiveKey={["xprvKey"]}
            onChange={(key) => setIsActive(key.length > 0)}
            className={classNames(style.collapse, { [style.collapseActive]: isActive })}
            expandIcon={({ isActive }) => <i className={`xi xi-chevron_${isActive ? "up" : "down"} me-2`} />}
            accordion
            ghost
            items={[
              // {
              //   key: "vault",
              //   label: (
              //     <span>
              //       <span className="me-2">XRAY/Vault Connect</span>
              //       <Tag color="default">soon</Tag>
              //     </span>
              //   ),
              //   children: <Vault />,
              // },
              {
                key: "browserConnectors",
                label: "1. Browser Connectors",
                children: <BrowserConnectors />,
              },
              {
                key: "privateKey",
                label: "2. Mnemonic or Private Key",
                children: <PrivateKey />,
              },
              {
                key: "hardwareWallets",
                label: (
                  <span>
                    <span className="me-2">3. Hardware Wallets</span>
                    <Tag color="default">soon</Tag>
                  </span>
                ),
                children: <HardwareWallets />,
              },
              {
                key: "readonly",
                label: "4. Read-Only Connect",
                children: <Readonly />,
              },
              {
                key: "importAccount",
                label: "5. Import Key File",
                children: <ImportAccount />,
              },
            ]}
          />
        </div>
      )}
      <div className="mt-4 font-size-14 text-muted">
        <span>By connecting a wallet, you agree to the</span>{" "}
        <strong>
          <Link
            to="/wiki/terms-of-service"
            onClick={() => {
              dispatch(AppActions.MODAL_ACCOUNTS_SET(false))
              dispatch(AppActions.MODAL_ADD_ACCOUNT_SET(false))
            }}
          >
            Terms of Service
          </Link>{" "}
          &{" "}
          <Link
            to="/wiki/privacy-policy"
            onClick={() => {
              dispatch(AppActions.MODAL_ACCOUNTS_SET(false))
              dispatch(AppActions.MODAL_ADD_ACCOUNT_SET(false))
            }}
          >
            Privacy Policy
          </Link>
        </strong>
      </div>
      <div className="mt-2 font-size-14 text-muted">
        <span className="me-3">
          Powered with
          <a href="https://cardano.org" target="_blank" className={style.cardano}>
            <SVGCardano />
            <strong>Cardano</strong>
          </a>
        </span>
        <span>
          Need help?{" "}
          <strong>
            <Link
              to="/wiki"
              onClick={() => {
                dispatch(AppActions.MODAL_ACCOUNTS_SET(false))
                dispatch(AppActions.MODAL_ADD_ACCOUNT_SET(false))
              }}
            >
              Read our Wiki
            </Link>
          </strong>
        </span>
      </div>
    </Modal>
  )
}

export default ModalAddAccount
