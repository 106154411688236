import * as utils from "@/utils"
import * as ConfigTypes from "./types"
export * as ConfigTypes from "./types"

import miniApps from "./apps"
export { miniApps }

import widgets from "./widgets"
export { widgets }

export const LOCAL_STORAGE_PREFIX_GLOBAL = "xray.app."

export const TTL = 900

export const ADA_HANDLE_POLICY = "f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a"

export const SLOT_CONFIG_NETWORK: Record<ConfigTypes.T.NetworkName, ConfigTypes.T.SlotConfig> = {
  mainnet: { zeroTime: 1596059091000, zeroSlot: 4492800, slotDuration: 1000 },
  preview: { zeroTime: 1666656000000, zeroSlot: 0, slotDuration: 1000 },
  preprod: { zeroTime: 1654041600000 + 1728000000, zeroSlot: 86400, slotDuration: 1000 },
  custom: { zeroTime: 0, zeroSlot: 0, slotDuration: 0 },
}

export const SLOT_STARTING_EPOCH: Record<ConfigTypes.T.NetworkName, number> = {
  mainnet: 208,
  preprod: 4,
  preview: 0,
  custom: 0,
}

export const SLOT_EPOCH_DURATION: Record<ConfigTypes.T.NetworkName, number> = {
  mainnet: 432000,
  preprod: 432000,
  preview: 432000 / 5,
  custom: 0,
}

export const CURRENCY_SYMBOLS: Record<ConfigTypes.Currencies, string> = {
  usd: "$",
  eur: "€",
  gbp: "£",
  jpy: "¥",
  cny: "¥",
}

export const XRAY_POOLS = [
  "pool1rjxdqghfjw5rv6lxg8qhedkechvfgnsqhl8rrzwck9g45n43yql",
  "pool1ntfxj2jgvhzen8e86ed679ctukar3vj538ywyyq8ryld66jj4sx",
  "pool1yt868wrp9s2x5pehe96del9m3nwasme62yw02vww3kg6zwzspcz",
  "pool19ru9alj7n5anmzs0f9gpzlj8kaq44yhcp45mldjvldxck8f6yg6",
  "pool1szy2tdjj8a4av365wk3q2fqg9srllmt0383e6v9p664ng076x9e",
  "pool1r650ja5y4jy7xd6srjz8ujkumy8xpk8kqgrxu92g3nwyk5jf4zq",
  "pool1e6rjpsl42hsc52zc9s2awhv2df50yzw43xtc7d0vranpc9ej8uj",
  "pool16erldmmx5nvrwncujtp6lxs9tmvgxcpthd600pe6axm5z3p6522",
]

export const browserConnectors: ConfigTypes.BrowserConnectorData[] = [
  // {
  //   id: "xray",
  //   name: "XRAY/Wallet",
  //   imgUrl: "/resources/wallets/xray.svg",
  //   imgInversible: false,
  //   installUrl: "https://xray.app", // TODO: Update this URL
  // },
  {
    id: "eternl",
    name: "Eternl",
    imgUrl: "/resources/wallets/eternl.png",
    imgInversible: false,
    installUrl: "https://cardwallet.io",
  },
  {
    id: "metamask",
    name: "MetaMask",
    imgUrl: "/resources/wallets/metamask.svg",
    imgInversible: false,
    installUrl: "https://metamask.io",
  },
  {
    id: "nami",
    name: "Nami",
    imgUrl: "/resources/wallets/nami.svg",
    imgInversible: false,
    installUrl: "https://namiwallet.io",
  },
  {
    id: "lace",
    name: "Lace",
    imgUrl: "/resources/wallets/lace.svg",
    imgInversible: false,
    installUrl: "https://lace.io",
  },
  {
    id: "nufi",
    name: "NuFi",
    imgUrl: "/resources/wallets/nufi.svg",
    imgInversible: false,
    installUrl: "https://nu.fi",
  },
  {
    id: "vespr",
    name: "Vespr",
    imgUrl: "/resources/wallets/vespr.png",
    imgInversible: false,
    installUrl: "https://vespr.xyz",
  },
  {
    id: "yoroi",
    name: "Yoroi",
    imgUrl: "/resources/wallets/yoroi.svg",
    imgInversible: false,
    installUrl: "https://yoroi-wallet.com",
  },
  {
    id: "exodus",
    name: "Exodus",
    imgUrl: "/resources/wallets/exodus.svg",
    imgInversible: false,
    installUrl: "https://www.exodus.com",
  },
  {
    id: "typhoon",
    name: "Typhoon",
    imgUrl: "/resources/wallets/typhoon.svg",
    imgInversible: false,
    installUrl: "https://typhonwallet.io",
  },
  {
    id: "gero",
    name: "Gero",
    imgUrl: "/resources/wallets/gero.svg",
    imgInversible: false,
    installUrl: "https://gerowallet.io",
  },
  {
    id: "begin",
    name: "Begin",
    imgUrl: "/resources/wallets/begin.svg",
    imgInversible: false,
    installUrl: "https://begin.is",
  },
]

export const browserConnectorData = (id: string): ConfigTypes.BrowserConnectorData => {
  return (
    browserConnectors.find((connector) => connector.id === id) ?? {
      id,
      name: utils.capitalizeFirstLetter(id),
      imgUrl: "/resources/wallets/wallet.png",
      imgInversible: true,
      installUrl: "",
    }
  )
}
