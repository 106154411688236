import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import { AppActions, AppTypes } from "."
import { AccountActions, AccountTypes } from "@/redux/account"
import { typedSelect, typedCall } from "@/redux/provider"
import { getFiatPrice } from "@/services/fiatPrice"
import { CardanoWeb3, KoiosProvider } from "cardano-web3-js"
import * as Config from "@/config"

export function* WEB3_INIT_SAGA({ networkName }: AppTypes.AWeb3InitSaga) {
  const web3 = yield* typedCall(CardanoWeb3.init, {
    network: networkName,
    ttl: Config.TTL,
    // @ts-ignore
    // explorer: { koios: { url: "http://localhost:8787/output/koios/mainnet/api/v1" } },
    // provider: new KoiosProvider("http://localhost:8787/output/koios/mainnet/api/v1"),
  })
  yield put(AppActions.WEB3_SET(web3))
}

export function* NETWORK_UPDATE_SAGA({ networkName }: AppTypes.AWeb3InitSaga) {
  const networkId = networkName === "mainnet" ? 1 : 0
  yield put(
    AppActions.NETWORK_SET({
      name: networkName,
      id: networkId,
    })
  )
}

export function* CONNECTORS_ONLINE_UPDATE_SAGA() {
  try {
    const web3 = yield* typedSelect((state) => state.app.web3.instance)
    const connectors = yield* typedCall(web3!.connector.list)
    const connectorsOnline: AppTypes.ConnectorOnline[] = []
    for (const connector of connectors) {
      const isEnabled = yield* typedCall(web3!.connector.isEnabled, connector)
      try {
        if (isEnabled) {
          const __connector = yield* typedCall(web3!.connector.init, connector)
          const addressHex =
            (yield* typedCall(__connector.getUsedAddresses))[0] || (yield* typedCall(__connector.getUnusedAddresses))[0]
          const address = (yield* typedCall(web3!.CML.Address.from_hex, addressHex)).to_bech32()
          const network = yield* typedSelect((state) => state.app.network)
          connectorsOnline.push({
            id: connector,
            isEnabled: true,
            address,
            networkId: network.id,
          })
        }
      } catch {}
    }
    yield put(AppActions.CONNECTORS_ONLINE_SET(connectorsOnline))
  } catch {}
}

export function* TIP_UPDATE_SAGA() {
  try {
    const web3 = yield* typedSelect((state) => state.app.web3.instance)
    const tip = yield* typedSelect((state) => state.app.tip)
    const newTip = yield* typedCall(web3!.tip)
    if (newTip.absSlot !== tip?.absSlot) {
      yield put(AppActions.TIP_SET(newTip))
    }
  } catch {}
}

export function* FIAT_PRICE_UPDATE_SAGA() {
  try {
    const fiatPrice = yield* typedCall(getFiatPrice)
    if (fiatPrice.updatedAt) {
      yield put(AppActions.FIAT_PRICE_SET(fiatPrice))
    }
  } catch {}
}

export default function* rootSaga() {
  yield all([
    takeEvery(AppTypes.Enum.WEB3_INIT_SAGA, WEB3_INIT_SAGA),
    takeEvery(AppTypes.Enum.CONNECTORS_ONLINE_UPDATE_SAGA, CONNECTORS_ONLINE_UPDATE_SAGA),
    takeEvery(AppTypes.Enum.NETWORK_UPDATE_SAGA, NETWORK_UPDATE_SAGA),
    takeEvery(AppTypes.Enum.TIP_UPDATE_SAGA, TIP_UPDATE_SAGA),
    takeEvery(AppTypes.Enum.FIAT_PRICE_UPDATE_SAGA, FIAT_PRICE_UPDATE_SAGA),
  ])
}
