import React, { useEffect, useState } from "react"
import { Button, Dropdown } from "antd"
import { navigate, Link } from "gatsby"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AccountActions } from "@/redux/account"
import { MiniappsSelectors } from "@/redux/miniapps"
import * as style from "./style.module.scss"

const MiniAppHeader = ({ id }: { id: string }) => {
  const dispatch = useAppDispatch()
  const miniAppsInstalled = useAppSelector(MiniappsSelectors.all)
  const miniApp = miniAppsInstalled.find((app) => app.id === id)
  const installed = useAppSelector((state) => state.account.miniApps.includes(id))

  // useEffect(() => {
  //   const handleSearchFocus = (e: any) => {
  //     try {
  //       if (e.code === "Escape") {
  //         closeRoute()
  //       }
  //     } catch {}
  //   }
  //   window?.addEventListener("keyup", handleSearchFocus)
  //   return () => {
  //     window?.removeEventListener("keyup", handleSearchFocus)
  //   }
  // }, [])

  return (
    <div className={style.header}>
      <div className="xray-app-header">
        <div className="xray-app-icon">
          <img src={`/resources/app-icons/${id}.png`} alt={miniApp?.name} />
        </div>
        <div className="xray-app-content">
          <strong className="xray-app-content-name">{miniApp?.name}</strong>
          <div className="xray-app-content-descr text-muted">
            {installed && (
              <span>
                <span className="text-success">
                  <i className="xi xi-check_circled me-1" />
                  installed
                </span>{" "}
                ·{" "}
              </span>
            )}
            {id}
          </div>
        </div>
      </div>

      <div className={style.close}>
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          menu={{
            items: [
              // {
              //   key: "info",
              //   onClick: () => {
              //     // dispatch(AppActions.MODAL_INFO_SET(app))
              //   },
              //   label: (
              //     <span>
              //       <i className="xi xi-info me-2" />
              //       Info
              //     </span>
              //   ),
              // },
              ...(installed
                ? [
                    {
                      key: "remove",
                      onClick: () => {
                        dispatch(AccountActions.MINI_APPS_UNINSTALL_SAGA(id))
                      },
                      label: (
                        <span>
                          <i className="xi xi-close_circled text-danger me-2" />
                          Uninstall
                        </span>
                      ),
                    },
                  ]
                : [
                    {
                      key: "install",
                      onClick: () => {
                        dispatch(AccountActions.MINI_APPS_INSTALL_SAGA(id))
                      },
                      label: (
                        <span>
                          <i className="xi xi-add_circled text-success me-2" />
                          Install
                        </span>
                      ),
                    },
                  ]),
            ],
          }}
        >
          <Button size="large" shape="round" className="me-2 px-3">
            <i className="xi xi-more_horizontal" />
          </Button>
        </Dropdown>
        <Link to="/">
          <Button size="large" shape="round" className="px-3">
            <i className="xi xi-close" />
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default MiniAppHeader
