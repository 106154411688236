import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { MDXProvider } from "@mdx-js/react"
import { Helmet } from "react-helmet"
import Menu from "@/components/pages/Wiki/Menu"
import NextPage from "@/components/pages/Wiki/NextPage"
import * as style from "./style.module.scss"

const WikiLayout = ({ children, location }: { children: React.ReactNode; location: any }) => {
  return (
    <div className="xray-page">
      <Helmet title="Wiki" />
      <MDXProvider>
        <div className={style.layout}>
          <div className={style.menu}>
            <Menu />
          </div>
          <div className={style.body}>
            <div className="wiki">{children}</div>
            <NextPage />
          </div>
        </div>
      </MDXProvider>
      <div className="mt-5 font-size-14 text-muted">
        XRAY/Network knowledge base.
        <br />
        Copyright &copy; {new Date().getFullYear()} XRAY/Network. All rights reserved.
      </div>
    </div>
  )
}

export default WikiLayout
