import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Modal, Button, Tabs } from "antd"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as Utils from "@/utils"
import AccountsList from "./AccountsList"
import AccountInfo from "./AccountInfo"
import AccountSettings from "./AccountSettings"

const ModalAccounts = () => {
  const dispatch = useAppDispatch()
  const modalAccounts = useAppSelector((state) => state.app.modalAccounts)
  const currentAccount = useAppSelector(AccountSelectors.currentAccountExtended)

  return (
    <Modal
      title={null}
      open={modalAccounts}
      onCancel={() => {
        dispatch(AppActions.MODAL_ACCOUNTS_SET(false))
      }}
      footer={null}
      width={650}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose={true}
    >
      <div className="mb-3">
        <strong>Connected Accounts</strong>
      </div>
      <div className="mb-4">
        <AccountsList />
      </div>
      <div className="mb-4">
        <Button
          shape="round"
          className="w-100p"
          size="large"
          onClick={() => {
            dispatch(AppActions.MODAL_ADD_ACCOUNT_SET(true))
          }}
        >
          <i className="xi xi-add font-size-12" />
          <strong>Add Another Account</strong>
        </Button>
      </div>
      {currentAccount && (
        <div>
          <Tabs
            destroyInactiveTabPane
            defaultActiveKey="account"
            items={[
              {
                key: "info",
                label: <strong>Account Info</strong>,
                children: <AccountInfo />,
              },
              {
                key: "settings",
                label: <strong>Account Settings</strong>,
                children: <AccountSettings />,
              },
            ]}
          />
        </div>
      )}
    </Modal>
  )
}

export default ModalAccounts
