import React, { useMemo } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import classNames from "classnames"
import { menuConfig } from "@/config/wiki"
import * as style from "./style.module.scss"

const NextPage = () => {
  const location = useLocation()
  const flatMenu = useMemo(() => {
    return menuConfig.flatMap((section) => section.items)
  }, [])
  const currentIndex = flatMenu.findIndex((item) => item.link === location.pathname)
  if (currentIndex === -1) return null
  const prevItem = flatMenu[currentIndex - 1]
  const nextItem = flatMenu[currentIndex + 1]

  return (
    <div className={classNames(style.container, "mt-5")}>
      {prevItem ? (
        <Link to={prevItem.link} className={style.item}>
          <span className="text-muted font-size-12">Previous Page</span>
          <br />
          {prevItem.title}
        </Link>
      ) : (
        <div />
      )}

      {nextItem ? (
        <Link to={nextItem.link} className={style.item}>
          <span className="text-muted font-size-12">Next Page</span>
          <br />
          {nextItem.title}
        </Link>
      ) : (
        <div />
      )}
    </div>
  )
}

export default NextPage
