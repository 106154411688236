import { AppTypes } from "."
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"
import { LOCAL_STORAGE_PREFIX_GLOBAL } from "@/config"
import { produce } from "immer"

const LOCAL_STORAGE_PREFIX = LOCAL_STORAGE_PREFIX_GLOBAL + "app."
const setLocalstorage = (key: string, value: any) => {
  setPersistSetting(LOCAL_STORAGE_PREFIX, key, value)
}

const initialState: AppTypes.ISettings = {
  loaded: false,
  cookiesViewed: false,
  theme: "system",
  scale: "default",
  modalAccounts: false,
  modalAddAccount: false,
  modalSettings: false,
  network: {
    name: "mainnet",
    id: 1,
  },
  currency: "usd",
  hideBalances: false,
  hideWelcome: false,
  accountAddedSuccessfully: false,
  explorer: "cardanoscan",
  tip: null,
  fiatPrice: null,
  connectorsOnline: [],
  web3: {
    instance: null,
  },
}

export default (
  state: AppTypes.ISettings = { ...getPersistSettings(LOCAL_STORAGE_PREFIX, initialState) },
  action: AppTypes.Actions
): AppTypes.ISettings => {
  switch (action.type) {
    case AppTypes.Enum.CLEAR:
      return initialState

    case AppTypes.Enum.LOADED_SET:
      return { ...state, loaded: action.loaded }

    case AppTypes.Enum.COOKIES_VIEWED_SET:
      setLocalstorage("cookiesViewed", action.cookiesViewed)
      return { ...state, cookiesViewed: action.cookiesViewed }

    case AppTypes.Enum.THEME_SET:
      setLocalstorage("theme", action.theme)
      return { ...state, theme: action.theme }

    case AppTypes.Enum.SCALE_SET:
      setLocalstorage("scale", action.scale)
      return { ...state, scale: action.scale }

    case AppTypes.Enum.MODAL_ACCOUNTS_SET:
      return { ...state, modalAccounts: action.modalAccounts }

    case AppTypes.Enum.MODAL_ADD_ACCOUNT_SET:
      return { ...state, modalAddAccount: action.modalAddAccount }

    case AppTypes.Enum.MODAL_SETTINGS_SET:
      return { ...state, modalSettings: action.modalSettings }

    case AppTypes.Enum.NETWORK_SET:
      setLocalstorage("network", action.network)
      return { ...state, network: action.network }

    case AppTypes.Enum.WEB3_SET:
      return produce(state, (draft) => {
        draft.web3 = {
          instance: action.web3,
        }
      })

    case AppTypes.Enum.CONNECTORS_ONLINE_SET:
      return { ...state, connectorsOnline: action.connectorsOnline }

    case AppTypes.Enum.TIP_SET:
      return { ...state, tip: action.tip }

    case AppTypes.Enum.FIAT_PRICE_SET:
      return { ...state, fiatPrice: action.fiatPrice }

    case AppTypes.Enum.EXPLORER_SET:
      setLocalstorage("explorer", action.explorer)
      return { ...state, explorer: action.explorer }

    case AppTypes.Enum.CURRENCY_SET:
      setLocalstorage("currency", action.currency)
      return { ...state, currency: action.currency }

    case AppTypes.Enum.HIDE_BALANCES_SET:
      setLocalstorage("hideBalances", action.hideBalances)
      return { ...state, hideBalances: action.hideBalances }

    case AppTypes.Enum.HIDE_WELCOME_SET:
      setLocalstorage("hideWelcome", action.hideWelcome)
      return { ...state, hideWelcome: action.hideWelcome }

    case AppTypes.Enum.ACCOUNT_ADDED_SUCCESSFULLY_SET:
      return { ...state, accountAddedSuccessfully: action.accountAddedSuccessfully }

    default:
      return state
  }
}
