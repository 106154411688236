import React from "react"
import * as style from "./style.module.scss"

const BarChart = ({ values, height = 1 }: { values: { epoch_no: number; epoch_ros: number }[]; height?: number }) => {
  const __values = Array.from({ length: 7 }).map((item, index) => {
    return values[index]?.epoch_ros || 0
  })

  return (
    <div className={style.ros} style={{ height: `${height}rem` }}>
      {__values.map((item, index) => {
        return <div key={index} className={style.item} style={{ height: `${(item > 7 ? 7 : item) * 20}%` }} />
      })}
    </div>
  )
}

export default BarChart
