import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, Checkbox, FormProps, Alert } from "antd"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import { Link } from "gatsby"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as utils from "@/utils"
import { set } from "store"

type FormFieldsType = {
  key: string
  password: string
  confirm: string
}

const Create = () => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const web3 = useAppSelector((state) => state.app.web3.instance)
  const [generatedMnemonic, setGeneratedMnemonic] = useState("")
  const [confirmedMnemonic, setConfirmedMnemonic] = useState("")
  const [wroteDownMnemonic, setWroteDownMnemonic] = useState(false)
  const [mnemonicApproved, setMnemonicApproved] = useState(false)
  const [passwordApproved, setPasswordApproved] = useState(false)

  useEffect(() => {
    generateNewMnemonic()
  }, [web3])

  const unlockWallet: FormProps<FormFieldsType>["onFinish"] = (values) => {
    const { key, password } = values
    const xprvKey = web3?.utils.keys.mnemonicToXprvKey(key)
    dispatch(AccountActions.ADD_XPRV_KEY_SAGA(xprvKey!, password))
    clearForm()
  }

  const generateNewMnemonic = () => {
    const mnemonicPhrase = web3?.utils.keys.mnemonicGenerate()
    setGeneratedMnemonic(mnemonicPhrase!)
  }

  const clearForm = () => {
    form.resetFields()
    generateNewMnemonic()
    setConfirmedMnemonic("")
    setWroteDownMnemonic(false)
    setWroteDownMnemonic(false)
    setMnemonicApproved(false)
    setPasswordApproved(false)
  }

  return (
    <div>
      <p className="text-muted">A new wallet will be created with the mnemonic phrase shown below</p>
      <Form form={form} layout="vertical" requiredMark={false} onFinish={unlockWallet}>
        <Form.Item
          label={
            <div className="xray-form-label">
              <span className="me-2">Mnemonic Phrase</span>
              <a onClick={clearForm} className="xray-link">
                Re-generate
              </a>
            </div>
          }
        >
          <div className={style.mnemonicArea}>{generatedMnemonic}</div>{" "}
        </Form.Item>
        <Form.Item
          name="wroteDownMnemonic"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                if (value) {
                  setWroteDownMnemonic(true)
                  return Promise.resolve()
                } else {
                  setWroteDownMnemonic(false)
                  return Promise.reject(new Error("Make sure you have your mnemonic securely recorded"))
                }
              },
            },
          ]}
        >
          <Checkbox>I wrote down my wallet mnemonic</Checkbox>
        </Form.Item>

        <Form.Item<FormFieldsType>
          hidden={!wroteDownMnemonic}
          label="Repeat mnemonic"
          name="key"
          rules={[
            {
              required: true,
              message: "Repeat mnemonic is required",
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  setMnemonicApproved(false)
                  return Promise.resolve()
                }
                if (!value || value === generatedMnemonic) {
                  setMnemonicApproved(true)
                  return Promise.resolve()
                }
                setMnemonicApproved(false)
                return Promise.reject(new Error("Mnemonic is not the same"))
              },
            }),
          ]}
          hasFeedback
        >
          <Input.TextArea size="large" autoComplete="off" autoSize placeholder="Enter Mnemonic..." />
        </Form.Item>

        <Form.Item<FormFieldsType>
          hidden={!(wroteDownMnemonic && mnemonicApproved)}
          name="password"
          label="Set Password"
          rules={[
            {
              required: true,
              message: "Please input your password",
            },
            {
              min: 8,
              message: "Minimum 8 symbols",
            },
          ]}
          hasFeedback
        >
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item<FormFieldsType>
          hidden={!(wroteDownMnemonic && mnemonicApproved)}
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  setPasswordApproved(true)
                  return Promise.resolve()
                }
                setPasswordApproved(false)
                return Promise.reject(new Error("The passwords do not match"))
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item className="mb-0">
          <Button
            shape="round"
            // disabled={!(mnemonicApproved && wroteDownMnemonic && passwordApproved)}
            htmlType="submit"
            size="large"
            type="primary"
          >
            <i className="xi xi-unlock" />
            <strong>Unlock Account</strong>
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Create
