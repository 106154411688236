import React, { useEffect, useState } from "react"
import AOS from "aos"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { Helmet } from "react-helmet"
import Header from "@/components/common/Header"
import NetworkStats from "@/components/common/NetworkStats"
import ModalAccounts from "@/components/modals/Accounts"
import ModalAddAccount from "@/components/modals/AddAccount"
import ModalSettings from "@/components/modals/Settings"
import ModalTransaction from "@/components/modals/Transaction"
import { capitalizeFirstLetter } from "@/utils"
import * as style from "./style.module.scss"

const MainLayout = ({ children, location }: { children: React.ReactNode; location: any }) => {
  const network = useAppSelector((state) => state.app.network)

  useEffect(() => {
    AOS.init({
      duration: 700,
      once: true,
      mirror: false,
    })
  }, [])

  return (
    <>
      <Helmet titleTemplate={"%s — XRAY/App"}>
        <meta
          name="viewport"
          content="width=device-width,viewport-fit=cover,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=0"
        />
        <link
          rel="preload"
          href="https://cdn.xray.app/fonts/satoshi/Satoshi-Medium.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href="https://cdn.xray.app/fonts/satoshi/Satoshi-Bold.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href="https://cdn.xray.app/fonts/xray-icons/Xray-Icons.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href="https://cdn.xray.app/fonts/archivo/Archivo-Variable.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        ></link>
        <link rel="stylesheet" type="text/css" href="https://cdn.xray.app/fonts/satoshi.css" />
        <link rel="stylesheet" type="text/css" href="https://cdn.xray.app/fonts/xray-icons.css" />
        <link rel="stylesheet" type="text/css" href="https://cdn.xray.app/fonts/archivo.css" />
      </Helmet>
      <ModalAccounts />
      <ModalAddAccount />
      <ModalSettings />
      <ModalTransaction />
      {network.name !== "mainnet" && (
        <div className={style.network}>Testnet Network: {capitalizeFirstLetter(network.name)}</div>
      )}
      <div className={style.header}>
        <Header />
      </div>
      <div className={style.body}>{children}</div>
    </>
  )
}

export default MainLayout
