import React, { useEffect, useState } from "react"
import { CheckCircleFilled } from "@ant-design/icons"
import { Button, Row, Col, Input, message } from "antd"
import Informers from "@/components/informers"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"
import LinkExplorer from "@/components/common/LinkExplorer"
import * as Utils from "@/utils"
import * as UtilsTxCw3 from "@/utils/txCw3"
import { AccountSelectors } from "@/redux/account"
import { ConfigTypes } from "@/config"

const Sign = ({ kind }: { kind?: string }) => {
  const dispatch = useAppDispatch()
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const web3 = useAppSelector((state) => state.app.web3.instance)
  const currentAccountId = useAppSelector(AccountSelectors.currentAccountId)
  const currentAccountExtended = useAppSelector(AccountSelectors.currentAccountExtended)
  const transaction = useAppSelector((state) => state.transaction.transaction)
  const [transactionData, setTransactionData] = useState<ReturnType<typeof UtilsTxCw3.parseJsonTx>>()
  const [flattenedOutputs, setFlattenedOutputs] = useState<ReturnType<typeof UtilsTxCw3.flattenOutputs>>()

  const tx = transaction!.tx
  const outputs = transaction!.outputs

  useEffect(() => {
    if (tx) {
      const run = async () => {
        const web3tx = web3?.importTx(tx!)
        const txData = await web3tx!.applyAndToJson()
        const transactionData = UtilsTxCw3.parseJsonTx(txData.json)
        setTransactionData(transactionData)

        const flattenedOutputs = UtilsTxCw3.flattenOutputs(outputs || [])
        setFlattenedOutputs(flattenedOutputs)
      }
      run()
    }
  }, [tx])

  const submitTx = async () => {
    if (password === "") {
      message.error("Enter account password")
      return
    }
    try {
      setPassword("")
      setLoading(true)
      const web3tx = web3?.importTx(tx!)

      const xprvKey = currentAccountExtended?.web3account?.decodeXprvKey(password)
      const vrfKey = web3?.utils.keys.xprvToVrfKey(
        xprvKey!,
        currentAccountExtended?.web3account?.__config.accountPath,
        currentAccountExtended?.web3account?.__config.addressPath
      )

      const stakingVrfKey = web3?.utils.keys.xprvToVrfKey(
        xprvKey!,
        currentAccountExtended?.web3account?.__config.accountPath,
        [2, 0]
      )

      const preparedTx = web3tx?.signWithVrfKey(vrfKey!)

      if (kind === "withdraw" || kind === "deregister" || kind === "register" || kind === "delegate") {
        preparedTx?.signWithVrfKey(stakingVrfKey!)
      }

      const signedTx = await preparedTx!.applyAndToJson()

      try {
        const hash = await web3?.submitTx(signedTx!.tx)
        dispatch(TransactionActions.PENDING_TX_UPDATE_SAGA(hash!))
      } catch (error) {
        message.error("Transaction error")
        console.log("Transaction error", error)
      }
    } catch (error) {
      message.error("Wrong password")
    }
  }

  return (
    <div className="xray-tx">
      <div className="mb-4">
        <strong>Transaction Sign</strong>
      </div>
      {kind === "delegate" && (
        <div className="mt-4 mb-4 pb-2">
          <div className="text-center mt-2 font-size-24 mb-4">
            <strong>Pool Delegation</strong>
          </div>
          <div className="text-center w-max-400 text-muted font-size-14 mx-auto">
            You are delegating your stake to a pool. Your funds will remain in your account.
          </div>
        </div>
      )}
      {kind === "register" && (
        <div className="mt-4 mb-4 pb-2">
          <div className="text-center mt-2 font-size-24 mb-4">
            <strong>Stake Registration & Pool Delegation</strong>
          </div>
          <div className="text-center w-max-400 text-muted font-size-14 mx-auto">
            You are registering your stake and delegating it to a pool. 2.000000 ADA will be sent as a deposit. Your
            funds will remain in your account.
          </div>
        </div>
      )}
      {kind === "deregister" && (
        <div className="mt-4 mb-4 pb-2">
          <div className="text-center mt-2 font-size-24 mb-4">
            <strong>Stake Deregistration</strong>
          </div>
          <div className="text-center w-max-400 text-muted font-size-14 mx-auto">
            You are deregistering your stake. 2.000000 ADA deposit will be returned to your account.
          </div>
        </div>
      )}
      {kind === "withdraw" && (
        <div className="mt-4 mb-4 pb-2">
          <div className="text-center mt-2 font-size-24 mb-4">
            <strong>Rewards Withdrawal</strong>
          </div>
          <div className="text-center w-max-400 text-muted font-size-14 mx-auto">
            You are withdrawing your rewards. All rewards will be sent to your account.
          </div>
        </div>
      )}
      {outputs && (
        <div className="mb-4">
          {outputs?.map((output, index) => {
            return (
              <div key={index} className="d-flex mb-3">
                <div className="xray-block-arrow me-3">
                  {/* <i className="xi xi-arrow_forward" /> */}
                  {outputs.length <= 1 && <i className="xi xi-arrow_forward" />}
                  {outputs.length > 1 && <strong>{index + 1}</strong>}
                </div>
                <div>
                  <div>
                    <Informers.Text value={Utils.truncate(output.address || "")} copy={output.address || ""} />
                  </div>
                  <div>
                    <div className="d-inline-flex me-3">
                      <Informers.Ada quantity={output.value || "0"} sameSize />
                    </div>
                    {output.assets?.map((asset, index) => {
                      return (
                        <div key={index} className="d-inline-flex me-3">
                          <Informers.Asset
                            policyId={asset.policyId}
                            assetName={asset.assetName}
                            quantity={asset.quantity}
                            decimals={asset.decimals}
                            sameSize
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className="xray-line xray-line-dashed mt-2 mb-4" />
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <div className="xray-box mb-3">
              <div className="xray-box-inner">
                <Informers.Breakdown
                  items={[
                    {
                      title: "ADA Subtotal",
                      children: (
                        <span className="font-size-16">
                          <Informers.Ada quantity={flattenedOutputs?.value || "0"} sameSize />
                        </span>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="xray-box xray-box-bg mb-3">
              <div className="xray-box-inner">
                <Informers.Breakdown
                  items={[
                    {
                      title: "+ Tx Fee",
                      children: (
                        <span className="font-size-16">
                          <Informers.Ada quantity={transactionData?.fee || "0"} sameSize />
                        </span>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="xray-line xray-line-dashed mt-2 mb-4" />
      <div className="mb-4">
        <form name="sendTransaction">
          <input
            id="user-text-field"
            hidden
            value={currentAccountId || undefined}
            readOnly
            type="text"
            autoComplete="username"
          />
          <Input.Password
            size="large"
            placeholder="Account Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            id="password-text-field"
            allowClear
          />
        </form>
      </div>
      <div className="">
        <Button size="large" shape="round" type="primary" className="w-100p" onClick={submitTx} loading={loading}>
          <i className="xi xi-send" />
          <span>Send</span>
        </Button>
      </div>
    </div>
  )
}

export default Sign
