import { Buffer } from "buffer"
import { ConfigTypes, SLOT_CONFIG_NETWORK, SLOT_EPOCH_DURATION, SLOT_STARTING_EPOCH } from "@/config"
import { crc8 } from "./crc8"
import { format, addDays, addHours } from "date-fns"

export const quantityWithCommas = (number: number | string | bigint | undefined): string => {
  return (number || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const quantityWithLetter = (number: number | string | bigint | undefined) => {
  return Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 2 }).format(Number(number)) || "0"
}

export const quantityFormat = (
  __value: number | string | bigint | undefined,
  decimals: number = 6,
  skipZero: boolean = false
) => {
  const value = (__value || 0).toString()
  if (skipZero && value === "0") {
    return {
      a: "0",
      b: "",
      final: "0",
    }
  }
  if (decimals <= 0) {
    return {
      a: quantityWithCommas(value),
      b: "",
      final: quantityWithCommas(value),
    }
  }
  const a = quantityWithCommas(value.slice(0, -decimals)) || "0"
  const b = value.slice(-decimals).padStart(decimals, "0")

  return {
    a,
    b,
    final: `${a}.${b.padEnd(decimals, "0")}`,
  }
}

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}

export const randomString = () => (Math.random() + 1).toString(36).substring(2)

export const truncate = (address: string, start = 6, end = 6) => {
  return `${address.slice(0, start)}...${address.slice(-end)}`
}

export const numberFromString = (string: string) => {
  const a = string.slice(7, 15)
  let encode = ""
  for (let i = 0; i < a.length; i += 1) {
    const x = a.slice(i, i + 1)
    encode += x.charCodeAt(0)
  }

  return parseInt(encode, 10)
}

export const stringToBinary = (string: string) => {
  const characters = string.split("")
  let binaryString = ""

  for (let i = 0; i < characters.length; i++) {
    const binary = characters[i].charCodeAt(0).toString(2)
    const pad = Math.max(8 - binary.length, 0)
    binaryString += "0".repeat(pad) + binary
  }

  return binaryString
}

export const binaryToString = (input: string) => {
  let bytesLeft = input
  let result = ""

  while (bytesLeft.length) {
    const byte = bytesLeft.substr(0, 8)
    bytesLeft = bytesLeft.substr(8)
    result += String.fromCharCode(parseInt(byte, 2))
  }

  return result
}

export const isChromiumBased = () => {
  return !!(global?.window as any)?.chrome && !!(global?.window as any).chrome.app
}

export const epochStartTime = (epoch: number, network: ConfigTypes.T.NetworkName) => {
  const config = SLOT_CONFIG_NETWORK[network]
  const startingEpoch = SLOT_STARTING_EPOCH[network]
  const epochDuration = SLOT_EPOCH_DURATION[network]
  return (epoch * epochDuration + (config.zeroTime / 1000 - startingEpoch * 432000)) * 1000
}

export const epochEndTime = (epoch: number, network: ConfigTypes.T.NetworkName) => {
  const config = SLOT_CONFIG_NETWORK[network]
  const startingEpoch = SLOT_STARTING_EPOCH[network]
  const epochDuration = SLOT_EPOCH_DURATION[network]
  return (epoch * epochDuration + epochDuration + (config.zeroTime / 1000 - startingEpoch * 432000)) * 1000
}

export const cip67Crc8Checksum = (num: string): string => {
  return crc8(Buffer.from(num, "hex")).toString(16).padStart(2, "0")
}

export function cip67FromLabel(label: string): number | undefined {
  if (label.length !== 8 || !(label[0] === "0" && label[7] === "0")) {
    return undefined
  }
  const numHex = label.slice(1, 5)
  const num = parseInt(numHex, 16)
  const check = label.slice(5, 7)
  return check === cip67Crc8Checksum(numHex) ? num : undefined
}

export const decodeAssetName = (assetName: string) => {
  try {
    const decode = (assetName: string) => {
      const labelHex = (assetName || "").substring(0, 8)
      const assetNameHex = (assetName || "").substring(8)
      const label = cip67FromLabel(labelHex)
      if (label) {
        return {
          assetNameAsciiNoLabel: Buffer.from(assetNameHex || "", "hex").toString("utf-8") || "",
          label: labelHex,
          labelAscii: label,
          labelType: labelType(label),
        }
      } else {
        return undefined
      }
    }
    const decoded = decode(assetName)
    const assetNameAscii = Buffer.from(assetName || "", "hex").toString("utf-8") || ""
    const assetNameAsciiNoLabel = decoded?.assetNameAsciiNoLabel || assetNameAscii
    const format = /^([/\\\[\]*<>(),.!?@+=%&$#^'"|a-zA-Z0-9 _-]+)$/
    const assetNameFinal = format.test(assetNameAsciiNoLabel) ? assetNameAsciiNoLabel : assetName
    const assetNameFinalWithLabel = (decoded?.label ? `(${decoded?.labelAscii}) ` : "") + assetNameFinal
    return {
      assetName: assetName,
      assetNameAscii: assetNameAscii,
      assetNameAsciiNoLabel: assetNameAsciiNoLabel,
      assetNameFinal: assetNameFinal,
      assetNameFinalWithLabel: assetNameFinalWithLabel,
      label: decoded?.label,
      labelAscii: decoded?.labelAscii,
      labelType: decoded?.labelType,
    }
  } catch (error) {
    console.log("decodeAssetName", error)
    return undefined
  }
}

export const labelType = (label: number): string | undefined => {
  switch (label) {
    case 100:
      return "ref"
    case 222:
      return "nft"
    case 333:
      return "ft"
    case 444:
      return "rft"
    case 500:
      return "royalty"
    default:
      return undefined
  }
}

export const timeAgo = (timestamp: number, locale: string = "en"): string => {
  const diffInSeconds = Math.floor((Date.now() - timestamp) / 1000)
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" })

  const timeUnits = [
    { unit: "year", seconds: 60 * 60 * 24 * 365 },
    { unit: "month", seconds: 60 * 60 * 24 * 30 },
    { unit: "day", seconds: 60 * 60 * 24 },
    { unit: "hour", seconds: 60 * 60 },
    { unit: "minute", seconds: 60 },
    { unit: "second", seconds: 1 },
  ]

  for (const { unit, seconds } of timeUnits) {
    const value = Math.floor(diffInSeconds / seconds)
    if (value > 0) {
      return rtf.format(-value, unit as Intl.RelativeTimeFormatUnit)
    }
  }

  return rtf.format(0, "second")
}

export const unixTimeToSlot = (unixTime: number, network: ConfigTypes.NetworkName): number => {
  const slotConfig = SLOT_CONFIG_NETWORK[network]
  const timePassed = unixTime - slotConfig.zeroTime
  const slotsPassed = Math.floor(timePassed / slotConfig.slotDuration)
  return slotsPassed + slotConfig.zeroSlot
}

export const slotToUnixTime = (slot: number, network: ConfigTypes.NetworkName): number => {
  const slotConfig = SLOT_CONFIG_NETWORK[network]
  const msAfterBegin = (slot - slotConfig.zeroSlot) * slotConfig.slotDuration
  return slotConfig.zeroTime + msAfterBegin
}

export const dateFormat = (date: number) => {
  return format(new Date(date), "dd/MM/yy")
}

export const dateFormatWithTime = (date: number) => {
  return format(new Date(date), "dd/MM/yy HH:mm:ss")
}

export const pageSizeToContentRange = (currentPage: number, pageSize: number) => {
  return `${(currentPage * pageSize).toString()}-${currentPage * pageSize + pageSize - 1}`
}

export const contentRangeToTotalResults = (contentRange: string) => {
  const [, poolListTotalResults]: string[] = contentRange.split("/")
  return parseInt(poolListTotalResults)
}
