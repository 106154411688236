import { RootState, useAppSelector } from "../provider"
import { createSelector } from "@reduxjs/toolkit"

export const theme = createSelector([(state: RootState) => state.app.theme], (theme) => {
  return theme === "system"
    ? global?.window?.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "default"
    : theme === "default"
      ? "default"
      : "dark"
})
