export const menuConfig = [
  {
    title: "General",
    items: [
      { title: "XRAY/Network", link: "/wiki/xray-network/", icon: "xi xi-bank" },
      { title: "XRAY Tokenomics", link: "/wiki/xray-tokenomics/", icon: "xi xi-diamond" },
      { title: "Roadmap", link: "/wiki/roadmap/", icon: "xi xi-map" },
      { title: "Social Media", link: "/wiki/social-media/", icon: "xi xi-share" },
    ],
  },
  {
    title: "Ecosystem",
    items: [
      { title: "XRAY/App", link: "/wiki/xray-app/", icon: "xi xi-cursor" },
      { title: "XRAY/Graph", link: "/wiki/xray-graph/", icon: "xi xi-code" },
      { title: "XRAY/Vault", link: "/wiki/xray-vault/", icon: "xi xi-document" },
      { title: "XRAY/Foundation", link: "/wiki/xray-foundation/", icon: "xi xi-heart" },
      { title: "Mini Apps", link: "/wiki/mini-apps/", icon: "xi xi-category" },
      { title: "GitHub Updates", link: "/wiki/github-updates/", icon: "xi xi-code" },
    ],
  },
  // {
  //   title: "Cardano",
  //   items: [
  //     { title: "Wallets & Accounts", link: "/wiki/cardano/wallets-and-accounts/" },
  //     { title: "ADA Staking", link: "/wiki/cardano/ada-staking/" },
  //     { title: "DRep", link: "/wiki/cardano/drep/" },
  //     { title: "Smart Contracts", link: "/wiki/cardano/smart-contracts/" },
  //   ],
  // },
  {
    title: "Developers",
    items: [
      { title: "Mini Apps", link: "/wiki/dev/mini-apps/", icon: "xi xi-code" },
      { title: "CardanoWeb3.js", link: "/wiki/dev/cardano-web3-js/", icon: "xi xi-code" },
      { title: "XRAY/Graph", link: "/wiki/dev/xray-graph/", icon: "xi xi-code" },
      { title: "XRAY/Vault", link: "/wiki/dev/xray-vault/", icon: "xi xi-code" },
    ],
  },
  {
    title: "App Info",
    items: [
      { title: "Changelog", link: "/wiki/changelog/", icon: "xi xi-list" },
      { title: "Install App (PWA)", link: "/wiki/install-app-pwa/", icon: "xi xi-add_circled" },
    ],
  },
  {
    title: "Legal",
    items: [
      { title: "Terms of Service", link: "/wiki/terms-of-service/", icon: "xi xi-info" },
      { title: "Privacy Policy", link: "/wiki/privacy-policy/", icon: "xi xi-shield_check" },
    ],
  },
]
